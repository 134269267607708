import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import * as types from "./types";

export default {
  setSelectedWorkspaceId: (context, id) => {
    context.commit(types.SET_SELECTED_WORKSPACE_ID, id);
  },
  getWorkspaces: async (context, { teamId }) => {
    try {
      let workspaces = await API.get(API_NAME, `/teams/${teamId}/workspaces`);
      if (workspaces.length) {
        workspaces = workspaces.map(({ sk, workspaceName, ...i }) => ({
          id: sk.split("#")[1],
          name: workspaceName,
          ...i
        }));
        context.commit(types.SET_SELECTED_WORKSPACE_ID, workspaces[0].id);
      } else {
        context.commit(types.SET_SELECTED_WORKSPACE_ID, null);
      }
      context.commit(types.SET_WORKSPACES, workspaces);
    } catch (err) {
      context.commit(types.SET_GET_FORMS_ERROR, err);
    }
  },
  getForms: async (context, { teamId, workspaceId }) => {
    try {
      const forms = await API.get(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms`
      );
      context.commit("form/SET_FORMS", forms, { root: true });
    } catch (err) {
      context.commit(types.SET_GET_FORMS_ERROR, err);
    }
  },
  listArchiveForm: async (context, { teamId }) => {
    try {
      const forms = await API.get(API_NAME, `/teams/${teamId}/list-archive`);
      context.commit("form/SET_ARCHIVE_FORMS", forms, { root: true });
    } catch (err) {
      context.commit(types.SET_GET_FORMS_ERROR, err);
    }
  },
  deleteForm: async (context, { teamId, workspaceId, formId, vm }) => {
    try {
      await API.del(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}`
      );
      context.commit("form/DELETE_FORMS", formId, { root: true });
      vm.$toast.success(vm.$t("formDeletedPermanent"));
    } catch (err) {
      context.commit(types.SET_GET_FORMS_ERROR, err);
    }
  }
};
