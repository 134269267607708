import * as types from "./types";

export default {
  [types.SET_DATA](state, data) {
    state.data = data;
    state.hasProfiled = true;
  },
  [types.SET_PROFILE_ERROR](state, err) {
    console.debug("profile error: {}", err);
    state.profileStatus = {
      message: err.message,
      code: err.code
    };
  },
  [types.CLEAR_PROFILE_STATUS](state) {
    state.profileStatus = null;
  },
  [types.CLEAR_PROFILE](state) {
    state.data = null;
    state.hasProfiled = false;
  }
};
