export default {
  form: null,
  forms: [],
  archiveForms: [],
  selectedVersion: null,
  createFormStatus: null,
  getFormStatus: null,
  formStatus: null,
  isFormChanged: false,
  response: null,
  showFormLimitWarning: false,
  activeStepGuide: null
};
