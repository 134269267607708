import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import "./plugins/vuelidate";
import "./plugins/flowy-vue";
import "./styles/flowy-vue.css";
import "./styles/index.scss";
import "@/components";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Amplify, { Auth } from "aws-amplify";
import AwsConfig from "@/aws-exports";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import FlowyPlugin from "@/components/index.js";
import vSelect from "vue-select";
import VueTour from "vue-tour";
import VCalendar from "v-calendar";

Amplify.configure(AwsConfig);
Auth.configure(AwsConfig);

Vue.component("icon", require("../src/assets/svg/Icon").default);
Vue.component("logo", require("../src/assets/svg/Logo").default);
Vue.component("v-select", vSelect);

Vue.use(FlowyPlugin);
Vue.use(VueTour);
require("vue-tour/dist/vue-tour.css");

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-center",
  timeout: 1500,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});

Vue.use(VCalendar, {
  componentPrefix: "vc"
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
