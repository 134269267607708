import * as types from "./types";

export default {
  [types.SET_AUTHENTICATION_ERROR](state, err) {
    console.debug("auth error: {}", err);
    state.authenticationStatus = {
      state: "failed",
      message: err.message,
      code: err.code,
      variant: "danger"
    };
  },
  [types.CLEAR_AUTHENTICATION_STATUS]: state => {
    state.authenticationStatus = null;
  },
  [types.SET_USER_AUTHENTICATED](state, user) {
    state.user = user;
    state.isAuthenticated = true;
  },
  [types.SET_USER_ACTIVATED](state) {
    state.isAccountActivated = true;
  },
  [types.SET_TEMP](state, payload) {
    state.temp = payload;
  },
  [types.SET_USERNAME](state, payload) {
    state.username = payload;
  },
  [types.CLEAR_AUTHENTICATION](state) {
    state.user = null;
    state.userId = null;
    state.isAuthenticated = false;
  }
};
