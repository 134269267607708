<template>
  <b-form-input placeholder="Write your answer here..." type="text">
  </b-form-input>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    }
  }
};
</script>
