import { Auth } from "aws-amplify";
import * as types from "./types";
import router from "../../../router";
import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";

export default {
  clearAuthenticationStatus: context => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
  },
  currentAuthenticatedUser: async context => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
      context.commit(types.SET_USER_AUTHENTICATED, user);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  signIn: async (context, params) => {
    console.debug("signIn for {}", params.username);
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      const user = await Auth.signIn(params.username, params.password);

      context.commit(types.SET_USER_AUTHENTICATED, user);

      user.deviceKey = user.signInUserSession.accessToken.payload.device_key;

      if (params.remember) {
        user.setDeviceStatusRemembered({
          onSuccess: function(result) {
            console.log("call result: " + result);
          },

          onFailure: function(err) {
            console.log(err);
          }
        });
        localStorage.setItem("rememberDevice", true);
      } else {
        user.setDeviceStatusNotRemembered({
          onSuccess: function(result) {
            console.log("call result: " + result);
          },

          onFailure: function(err) {
            console.log(err);
          }
        });
        localStorage.setItem("rememberDevice", false);
      }
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  signOut: async context => {
    try {
      router.push({ name: "Login" });
      await Auth.signOut();
      localStorage.clear();
    } catch (err) {
      console.error("error during sign out: {}", err);
    }
    context.commit(types.CLEAR_AUTHENTICATION);
  },
  signUp: async (context, params) => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.signUp(params);
      context.commit(types.CLEAR_AUTHENTICATION);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  confirmSignUp: async (context, params) => {
    console.debug("confirm signup for {}", params.username);
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.confirmSignUp(params.username, params.code);
      context.commit(types.SET_USER_ACTIVATED);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  confirmResend: async (context, params) => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.resendSignUp(params.username);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  passwordReset: async (context, params) => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.forgotPassword(params.username);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  confirmPasswordReset: async (context, params) => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.forgotPasswordSubmit(
        params.username,
        params.code,
        params.password
      );
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
      throw new Error(err);
    }
  },
  passwordResetResend: async (context, params) => {
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      await Auth.passwordResetResend(params.username);
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  passwordChange: async (context, params) => {
    console.debug("password change for {}", context.state.user.username);
    context.commit(types.CLEAR_AUTHENTICATION_STATUS);
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        params.currentPassword,
        params.newPassword
      );
      context.dispatch("signOut");
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  resendVerificationCode: async (context, { email, vm }) => {
    try {
      const res = await API.post(API_NAME, `/profile/resend-verification`, {
        body: { email }
      });

      vm.$toast.success(
        `${vm.$t("confirmationResend")} ${res.CodeDeliveryDetails.Destination}`
      );
    } catch (err) {
      context.commit(types.SET_AUTHENTICATION_ERROR, err);
    }
  },
  setTemp: (context, value) => {
    context.commit(types.SET_TEMP, value);
  },
  setUsername: (context, value) => {
    context.commit(types.SET_USERNAME, value);
  }
};
