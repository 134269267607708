<template>
  <b-modal centered :id="modalId" no-stacking>
    <template #modal-header>
      <h5 class="mb-0 col-12 text-center">
        {{ $t("manageRespondentAccess") }}
      </h5>
    </template>
    <template #default>
      <div
        v-if="modalId === 'set-close-response'"
        class="col-12 d-flex justify-content-between align-items-center p-0"
      >
        <span style="color:#A1AEB7;">{{ $t("closeNow") }}</span>
        <b-form-radio class="p-0" disabled></b-form-radio>
      </div>
      <div
        class="col-12 d-flex justify-content-between align-items-center p-0"
        :class="modalId === 'set-close-response' ? 'mt-4' : ''"
      >
        <span>{{ $t("setResponseLimit") }}</span>
        <b-form-radio
          class="p-0"
          v-model="selectedOption"
          value="responseLimit"
          @change="selectedDate = null"
        ></b-form-radio>
      </div>
      <div
        v-if="selectedOption === 'responseLimit'"
        class="col-12 d-flex p-0 mt-2"
      >
        <b-input-group :append="$t('responses')">
          <b-form-input
            type="number"
            v-model="response"
            placeholder="Type the limit, eg: 100"
          ></b-form-input>
        </b-input-group>
      </div>
      <div
        class="col-12 d-flex justify-content-between align-items-center p-0 mt-4"
      >
        <span>{{ $t("scheduleDate") }}</span>
        <b-form-radio
          class="p-0"
          v-model="selectedOption"
          value="scheduleDate"
          @change="response = null"
        ></b-form-radio>
      </div>
      <div
        v-if="selectedOption === 'responseLimit' && response"
        class="alert-primary"
      >
        <span
          v-html="
            $t('formCloseByResponse', {
              selectedResponseLimit: response
            })
          "
        />
      </div>
      <div v-if="selectedOption === 'scheduleDate'">
        <div class="col-12 p-0 mt-2">
          <label for="time-zone">{{ $t("timeZone") }}</label>
          <b-form-select
            id="time-zone"
            :options="timezoneList"
            v-model="selectedTimezone"
          ></b-form-select>
        </div>
        <div class="col-12 d-flex flex-row p-0 mt-2">
          <div class="col-6 p-0">
            <label for="date-picker">{{ $t("closeDate") }}</label>
            <b-form-datepicker
              v-model="selectedDate"
              id="date-picker"
            ></b-form-datepicker>
          </div>
          <div class="col-6 pl-3 pr-0">
            <label for="close-time">{{ $t("closeTime") }}</label>
            <b-input-group id="close-time">
              <b-form-timepicker
                v-model="selectedTime"
                locale="en"
              ></b-form-timepicker>
            </b-input-group>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <b-button variant="light" class="float-left" @click="removeIndicator">
          {{ $t("removeCloseIndicator") }}
        </b-button>
        <b-button
          @click="
            setCloseForm({
              responseClosing:
                response === null ? response : parseInt(response, 10),
              dateClosing: buildDate()
            })
          "
          variant="primary"
          class="float-right"
        >
          {{ $t("save") }}
        </b-button>
        <b-button variant="light" class="float-right mr-2" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<i18n>
{
  "en": {
    "settings": "Settings",
    "manageRespondentAccess": "Manage respondent access",
    "setResponseLimit": "Set a response limit",
    "scheduleDate": "Schedule a close date",
    "formCloseByResponse": "Your form will be close when reach to <b>{selectedResponseLimit} responses</b>",
    "removeCloseIndicator": "Remove close indicator",
    "save": "Save",
    "cancel": "Cancel",
    "closeDate": "Close date",
    "closeTime": "Close time",
    "closeNow": "Close now",
    "timeZone": "Time zone",
    "responses": "Responses",
    "succeedSetCloseForm": "Succeed set form close indicator"
  },
  "id": {
    "settings": "Pengaturan",
    "manageRespondentAccess": "Kelola akses responden",
    "setResponseLimit": "Tetapkan batas tanggapan",
    "scheduleDate": "Jadwalkan tanggal tutup",
    "formCloseByResponse": "Formulir Anda akan ditutup ketika mencapai <b>{selectedResponseLimit} tanggapan</b>",
    "removeCloseIndicator": "Hapus Indikator",
    "save": "Simpan",
    "cancel": "Batal",
    "closeDate": "Tanggal tutup",
    "closeTime": "Waktu tutup",
    "closeNow": "Tutup sekarang",
    "timeZone": "Zona waktu",
    "responses": "Tanggapan",
    "succeedSetCloseForm": "Berhasil mengatur indikator tutup formulir"
  }
}
</i18n>

<script>
import timezones from "timezones-list";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    teamId: {
      type: String,
      required: true
    },
    workspaceId: {
      type: String,
      required: true
    },
    formId: {
      type: String,
      required: true
    },
    modalId: {
      type: String
    }
  },
  data() {
    return {
      response: null,
      selectedOption: null,
      selectedDate: null,
      selectedTimezone: "Asia/Jakarta (GMT+07:00)",
      selectedTime: null
    };
  },
  mounted() {
    const form = this.findForm(this.form.id, "PUBLISHED");
    if (form && form.closingForm.responseClosing) {
      this.selectedOption = "responseLimit";
      this.response = form.closingForm.responseClosing;
    }
    if (form && form.closingForm.dateClosing) {
      this.selectedOption = "scheduleDate";
      this.selectedDate = form.closingForm.dateClosing.selectedDate;
      this.selectedTimezone = form.closingForm.dateClosing.selectedTimezone;
      this.selectedTime = form.closingForm.dateClosing.selectedTime;
    }
  },
  computed: {
    ...mapGetters("form", ["versions", "form", "findForm"]),
    timezoneList() {
      return timezones.map(timezone => timezone.label);
    }
  },
  methods: {
    ...mapActions("form", ["setClosingForm"]),

    async removeIndicator() {
      await this.setCloseForm({
        response: null,
        date: null
      });
      this.resetForm();
    },
    resetForm() {
      this.response = null;
      this.selectedOption = null;
      this.selectedDate = null;
      this.selectedTimezone = "Asia/Jakarta (GMT+07:00)";
      this.selectedTime = null;
    },
    buildDate() {
      if (this.selectedOption === "scheduleDate") {
        return {
          selectedDate: this.selectedDate,
          selectedTimezone: this.selectedTimezone,
          selectedTime: this.selectedTime
        };
      }
      return null;
    },
    async setCloseForm(value) {
      await this.setClosingForm({
        teamId: this.teamId,
        workspaceId: this.workspaceId,
        formId: this.formId,
        value,
        vm: this
      });
      this.$bvModal.hide("set-close-response");

      if (this.modalId === "set-close-response2") {
        this.$bvModal.show("publish-modal");
      }
    },
    showCloseModal() {
      if (this.versions.includes("PUBLISHED")) {
        this.$bvModal.show("set-close-response");
      }
    },
    showCloseModal2() {
      if (this.versions.includes("PUBLISHED")) {
        this.$bvModal.show("set-close-response2");
      }
    }
  }
};
</script>
