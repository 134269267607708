<template>
  <b-dropdown
    text="Choose one of here"
    :style="{ backgroundColor: design.colors.answerColor }"
    class="dropdown-type"
    menu-class="dropdown-content"
  >
    <b-dropdown-item
      v-for="(option, index) in node.data.options"
      :key="index"
      style="width:100%;"
      >{{ option.value }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  }
};
</script>
