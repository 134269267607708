<template>
  <div class="opinion-scale-wrapper">
    <div class="opinion-scale">
      <div
        v-for="(n, index) in scaleStep"
        :key="index"
        class="opinion-scale-step"
        :style="{ backgroundColor: design.colors.answerColor }"
      >
        {{ n }}
      </div>
    </div>
    <div class="opinion-scale-label">
      <span :style="{ color: design.colors.descriptionColor }">
        {{ node.data.label.left }}
      </span>
      <span :style="{ color: design.colors.descriptionColor }">
        {{ node.data.label.right }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  },
  computed: {
    scaleStep() {
      let step;
      if (!this.node.data.scaleStep) {
        step = 10;
      } else {
        step = this.node.data.scaleStep;
      }
      return step;
    }
  }
};
</script>

<style scoped>
.opinion-scale {
  display: flex;
  flex-direction: row;
  width: auto;
}

.opinion-scale-step {
  height: 53px;
  color: white;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 4px;
}

.opinion-scale-label {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 580px) {
  .opinion-scale-step {
    max-width: 35px;
    max-height: 35px;
  }
}

@media screen and (max-width: 320px) {
  .opinion-scale-step {
    width: 25px;
    height: 25px;
    font-size: 10px;
  }
}

.opinion-scale-step:last-child {
  border: none;
  margin-right: 0 !important;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.opinion-scale-step:first-child {
  border: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
</style>
