<template>
  <div>
    <b-form-datepicker
      style="width:184px"
      placeholder="DD-MM-YYYY"
      :date-format-options="{
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }"
    ></b-form-datepicker>
  </div>
</template>

<script>
export default {
  props: {
    design: {
      type: Object
    }
  }
};
</script>
