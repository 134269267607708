<template>
  <transition name="scale">
    <div
      class="indicator"
      :class="{ 'not-allowed': !notAllowed }"
      v-show="show"
    >
      <h5 class="text-primary mb-0">Drop here</h5>
    </div>
  </transition>
</template>

<script>
export default {
  name: "DropIndicator",

  props: {
    show: Boolean,
    notAllowed: Boolean
  }
};
</script>

<style lang="scss" scoped>
.indicator {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d0e2f7;
  border: 1px dashed #166ed8;
  box-sizing: border-box;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.05, 0.03, 0.35, 1);
  transform: scale(1);
  position: absolute;
  bottom: -50px;
  z-index: 2;
}

.indicator .not-allowed {
  background-color: #f5365c;

  &:after {
    background-color: #f5365c;
  }
}
</style>
