import { render, staticRenderFns } from "./FlowyBlockNode.vue?vue&type=template&id=62866cf6&scoped=true&"
import script from "./FlowyBlockNode.vue?vue&type=script&lang=js&"
export * from "./FlowyBlockNode.vue?vue&type=script&lang=js&"
import style0 from "./FlowyBlockNode.vue?vue&type=style&index=0&id=62866cf6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62866cf6",
  null
  
)

/* custom blocks */
import block0 from "@/locales/common/form-field-type-names.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fcodebuild%2Foutput%2Fsrc562417754%2Fsrc%2Fgithub.com%2Fsurvein%2Fsurvein-admin%2Fsrc%2Fcomponents%2Fcommon%2FFlowyBlockNode.vue"
if (typeof block0 === 'function') block0(component)
import block1 from "./FlowyBlockNode.vue?vue&type=custom&index=1&blockType=i18n"
if (typeof block1 === 'function') block1(component)

export default component.exports