<template>
  <div>
    <b-container class="p-0">
      <b-row no-gutters>
        <b-col class="mr-1">
          <b-row
            no-gutters
            v-for="(option, index) in oddOptions"
            :key="index"
            class="mb-2"
          >
            <b-col>
              <div
                class="choice-option"
                :style="{ backgroundColor: design.colors.answerColor }"
              >
                <div
                  class="bullet"
                  :style="{ backgroundColor: design.colors.buttonTextColor }"
                ></div>
                <div class="choice-label">
                  <span :style="{ color: design.colors.buttonTextColor }">{{
                    option.value
                  }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ml-1 ">
          <b-row
            no-gutters
            v-for="(option, index) in evenOptions"
            :key="index"
            class="mb-2"
          >
            <b-col>
              <div
                class="choice-option"
                :style="{ backgroundColor: design.colors.answerColor }"
              >
                <div
                  class="bullet"
                  :style="{ backgroundColor: design.colors.buttonTextColor }"
                ></div>
                <div class="choice-label">
                  <span :style="{ color: design.colors.buttonTextColor }">{{
                    option.value
                  }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import FormBase from "./FormBase";

export default {
  extends: FormBase,
  props: {
    design: {
      type: Object
    }
  },
  computed: {
    evenOptions() {
      return this.node.data.options.filter((item, index) => index % 2);
    },
    oddOptions() {
      return this.node.data.options.filter((item, index) => !(index % 2));
    }
  }
};
</script>

<style lang="scss" scoped>
.choice-option {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding: 6px 12px;
  background-color: #dfeaf0;
  border-radius: 4px;

  .bullet {
    min-width: 16px;
    width: 16px;
    height: 16px;
    border-radius: 8px;
  }

  .choice-label {
    margin-left: 8px;
  }
}
</style>
