// This file contains components which has to register globally
import Vue from "vue";
import FlowyBlockNode from "@/components/common/FlowyBlockNode";
import FlowyBlockNodeStart from "@/components/common/FlowyBlockNodeStart";
import DraggablePlugin from "@hipsjs/shopify-draggable-vue";
import Flowy from "@/components/Flowy/Flowy.vue";
import FlowyNode from "@/components/Flowy/FlowyNode.vue";
import FlowyBlock from "@/components/Flowy/FlowyBlock.vue";
import FlowyNewBlock from "@/components/Flowy/FlowyNewBlock.vue";
import FlowyDragHandle from "@/components/Flowy/FlowyDragHandle";

Vue.component("FlowyBlockNode", FlowyBlockNode);
Vue.component("FlowyBlockNodeStart", FlowyBlockNodeStart);

// Declare install function executed by Vue.use()
export function install(_Vue) {
  const Vue = _Vue;
  Vue.use(DraggablePlugin, {
    handle: ".flowy-drag-handle"
  });

  Vue.component("Flowy", Flowy);
  Vue.component("FlowyNode", FlowyNode);
  Vue.component("FlowyBlock", FlowyBlock);
  Vue.component("FlowyNewBlock", FlowyNewBlock);
  Vue.component("FlowyDragHandle", FlowyDragHandle);
}

// Create module definition for Vue.use()
const plugin = {
  install
};

// UMD auto-install when vue is found (eg. in browser via <script> tag)
let GlobalVue = null;
if (typeof window !== "undefined") {
  GlobalVue = window.Vue;
} else if (typeof global !== "undefined") {
  GlobalVue = global.Vue;
}
if (GlobalVue) {
  GlobalVue.use(plugin);
}

export { Flowy, FlowyNode, FlowyBlock, FlowyNewBlock, FlowyDragHandle };

export default plugin;
