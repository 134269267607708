<template>
  <div>
    <b-container class="p-0">
      <b-row no-gutters class="choice-wrapper">
        <b-col
          class="choice-option"
          v-for="(option, index) in node.data.options"
          :key="index"
        >
          <b-form-checkbox
            @change="toggleCorrectAnswer(option.value)"
            :checked="node.data.correctAnswer.includes(option.value)"
            name="multiple-quiz-option"
            class="pl-0"
          >
          </b-form-checkbox>
          <div class="choice-label">
            <div class="img-wrapper" v-if="option.file">
              <b-img :src="buildImageLink(option.file.key)" class="img-fluid" />
            </div>
            <div class="label-text" v-else>
              <span class="m-auto">{{ option.value }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="node.data.options.length" class="mt-3">
        <b-col class="text-center">
          <span class="text-description">{{ $t("markAnswer") }}</span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<i18n>
{
  "en": {
    "formFieldSaved": "Form field saved",
    "markAnswer": "(Tick to mark the right answer)"
  },
  "id": {
    "formFieldSaved": "Bidang formulir disimpan",
    "markAnswer": "(Centang untuk menandai jawaban yang benar)"
  }
}
</i18n>

<script>
import FormBase from "./FormBase";
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  extends: FormBase,
  props: {
    design: {
      type: Object
    }
  },
  data() {
    return {
      correctAnswer: []
    };
  },
  computed: {
    ...mapGetters("form", ["form"])
  },
  methods: {
    ...mapActions("form", ["saveFormFields"]),

    async toggleCorrectAnswer(value) {
      const fields = cloneDeep(this.form.fields);

      const index = fields.indexOf(
        fields.find(field => field.id === this.node.data.id)
      );

      if (this.node.data.answerOptions === "multiple") {
        if (fields[index].correctAnswer.includes(value)) {
          fields[index].correctAnswer.splice(
            fields[index].correctAnswer.indexOf(value),
            1
          );
        } else {
          fields[index].correctAnswer = [...fields[index].correctAnswer, value];
        }
      } else {
        fields[index].correctAnswer = [value];
      }

      await this.saveFormFields({ fields, vm: this });
    },

    buildImageLink(key) {
      return `https://${process.env.VUE_APP_BUCKET_NAME}.s3.${process.env.VUE_APP_AWS_REGION}.amazonaws.com/${key}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.choice-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .choice-option {
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    width: 100%;
    max-width: 144px;
    height: 154px;
    padding: 12px 12px;
    background-color: #dfeaf0;
    border-radius: 8px;
    z-index: 999;

    &.choice-option:nth-child(1) {
      background: #166ed8;
    }
    &.choice-option:nth-child(2) {
      background: #ffa726;
    }
    &.choice-option:nth-child(3) {
      background: #f7195a;
    }
    &.choice-option:nth-child(4) {
      background: #4c3e8c;
    }

    .bullet {
      position: absolute;
      min-width: 16px;
      width: 16px;
      height: 16px;
    }

    .choice-label {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;

      .img-wrapper {
        height: 100px;
        border: 1px solid white;
      }

      .img-wrapper img {
        height: 100%;
        width: 100%;
      }

      .label-text {
        color: white;
        text-align: center;
      }
    }
  }
}

.label {
  width: 656px;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}

@media screen and (max-width: 768px) {
  .choice-option {
    margin-bottom: 4px;
  }
}
</style>
