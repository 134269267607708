import * as types from "./types";
import formVersionKeys from "@/constants/formVersionKeys";

export default {
  [types.SET_FORM](state, payload) {
    state.form = payload;
  },
  [types.SET_FORMS](state, payload) {
    state.forms = payload;
  },
  [types.SET_ARCHIVE_FORMS](state, payload) {
    state.archiveForms = payload;
  },
  [types.SET_PUBLISHED_FORM](state, payload) {
    state.forms[
      state.forms.findIndex(form => form.version === formVersionKeys.PUBLISHED)
    ] = payload;
  },
  [types.SET_SELECTED_VERSION](state, payload) {
    state.selectedVersion = payload;
  },
  [types.SET_CREATE_FORM_ERROR](state, payload) {
    state.createFormStatus = payload;
  },
  [types.SET_GET_FORM_ERROR](state, payload) {
    state.getFormStatus = payload;
  },
  [types.SET_FORM_ERROR](state, payload) {
    state.formStatus = payload;
  },
  [types.SET_IS_FORM_CHANGED](state, payload) {
    state.isFormChanged = payload;
  },
  [types.SET_RESPONSE](state, payload) {
    state.response = payload;
  },
  [types.SET_SHOW_FORM_LIMIT_WARNING](state, payload) {
    state.showFormLimitWarning = payload;
  },
  [types.SET_ACTIVE_STEP_GUIDE](state, payload) {
    state.activeStepGuide = payload;
  },
  [types.ADD_NEW_FORM](state, payload) {
    state.forms = [...state.forms, payload];
  },
  [types.ADD_NEW_FORMS](state, payload) {
    state.forms = [...state.forms, ...payload];
  },
  [types.DELETE_FORM](state, payload) {
    state.forms.splice(
      state.forms.indexOf(
        state.forms.find(
          form => form.id === payload.id && form.version === payload.version
        )
      ),
      1
    );
  },
  [types.DELETE_FORMS](state, payload) {
    const forms = state.forms.filter(form => form.id === payload);
    for (const form of forms) {
      state.forms.splice(state.forms.indexOf(form), 1);
    }
  }
};
