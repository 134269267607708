<template>
  <div>
    <b-input-group>
      <b-input-group-prepend>
        <multiselect
          v-model="selectedCountry"
          v-if="node.data.country === 'all'"
          :options="filteredCountries"
          :show-labels="false"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <country-flag :country="props.option.alpha2" />
            <span class="option__desc">
              <span class="option__title">{{
                props.option.countryCallingCodes
              }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="{ option }">
            <country-flag :country="option.alpha2" />
            <span class="option__desc">
              <span class="option__title">
                {{ option.name }} {{ option.countryCallingCodes }}
              </span>
            </span>
          </template>
        </multiselect>
      </b-input-group-prepend>
      <b-input-group-prepend
        v-if="node.data.country !== 'all'"
        class="d-flex justify-content-center align-items-center rounded-left"
        style="width: 100px; height:42px;"
        :style="{ backgroundColor: design.colors.answerColor }"
      >
        <country-flag :country="node.data.country.alpha2" />
        <span class="text ml-2" style="color: white;">{{
          node.data.country.countryCallingCodes[0]
        }}</span>
      </b-input-group-prepend>
      <b-form-input style="height: 42px" type="tel"></b-form-input>
    </b-input-group>
  </div>
</template>

<script>
const countries = require("country-data").countries;
import CountryFlag from "vue-country-flag";
import Multiselect from "vue-multiselect";

export default {
  components: {
    CountryFlag,
    Multiselect
  },
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  },
  data() {
    return {
      form: {},
      selectedCountry: {
        alpha2: "ID",
        countryCallingCodes: "+62"
      },
      filteredCountries: []
    };
  },
  mounted() {
    if (!this.node.data.country === "all") {
      this.selectedCountry = this.node.data.country;
    }
    this.filteredCountries = countries.all
      .filter(country => !!country.countryCallingCodes.length)
      .map(country => ({
        alpha2: country.alpha2,
        countryCallingCodes: country.countryCallingCodes[0]
      }));
  },
  methods: {}
};
</script>

<style scoped>
.text {
  font-size: 16px;
  font-family: "Proxima Nova", sans-serif;
  color: #606977;
}

span .option__title {
  position: relative;
  top: -4px;
  left: 4px;
}

.multiselect {
  max-width: 150px;
  height: 38px;
}

.multiselect__tags {
  height: 38px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
