import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login"
  },
  {
    path: "/login",
    name: "Login",
    props: route => ({ email: route.query.email }, { lang: route.query.lang }),
    component: () => import(/* webpackChunkName: "login" */ "../views/Login"),
    meta: { requiresAuth: false }
  },
  {
    path: "/register",
    name: "Register",
    props: route => ({ lang: route.query.lang }),
    component: () =>
      import(/* webpackChunkName: "register" */ "../views/Register"),
    meta: { requiresAuth: false }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    props: route => ({ lang: route.query.lang }),
    component: () =>
      import(/* webpackChunkName: "resetPassword" */ "../views/ResetPassword"),
    meta: { requiresAuth: false }
  },
  {
    path: "/confirm-reset-password/:email",
    name: "ConfirmResetPassword",
    component: () =>
      import(
        /* webpackChunkName: "confirmResetPassword" */ "../views/ConfirmResetPassword"
      ),
    meta: { requiresAuth: false }
  },
  {
    path: "/confirm/:email",
    name: "ConfirmSignUp",
    props: route => ({ email: route.query.email }, { lang: route.query.lang }),
    component: () =>
      import(
        /* webpackChunkName: "confirm-sign-up" */ "../views/ConfirmSignUp"
      ),
    meta: { requiresAuth: false }
  },
  {
    path: "/create-profile",
    name: "CreateProfile",
    component: () =>
      import(/* webpackChunkName: "create-profile" */ "../views/CreateProfile"),
    meta: { requiresAuth: true }
  },
  {
    path: "/workspace",
    name: "Workspace",
    component: () =>
      import(/* webpackChunkName: "workspace" */ "../views/Workspace"),
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "settings" */ "../views/Settings"),
    meta: { requiresAuth: true }
  },
  {
    path: "/teams/:teamId/workspaces/:workspaceId/forms/:formId",
    name: "Form",
    component: () => import(/* webpackChunkName: "form" */ "../views/Form"),
    props: true,
    children: [
      {
        path: "builder",
        name: "FormBuilder",
        props: true,
        component: () =>
          import(/* webpackChunkName: "form-builder" */ "../views/FormBuilder"),
        meta: { requiresAuth: true }
      },
      {
        path: "quiz",
        name: "FormBuilderQuiz",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-builder" */ "../views/FormBuilderQuiz"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "response",
        name: "FormResponse",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "form-builder" */ "../views/FormResponse"
          ),
        meta: { requiresAuth: true },
        children: [
          {
            path: "summary",
            name: "FormSummary",
            alias: "/",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "form-builder" */ "../components/FormResponse/FormSummaryLayout"
              ),
            meta: { requiresAuth: true }
          },
          {
            path: "details",
            name: "FormDetails",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "form-builder" */ "../components/FormResponse/FormDetailLayout"
              ),
            meta: { requiresAuth: true }
          },
          {
            path: "leaderboard",
            name: "FormLeaderboard",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "form-builder" */ "../components/FormResponse/FormLeaderboardLayout"
              ),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: "share",
        name: "FormShare",
        props: true,
        component: () =>
          import(/* webpackChunkName: "form-builder" */ "../views/FormShare"),
        meta: { requiresAuth: true }
      }
    ],
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
