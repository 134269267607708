import * as types from "./types";

export default {
  [types.SET_SELECTED_TEAM](state, team) {
    state.team = team;
  },
  [types.SET_SELECTED_PACKAGE](state, payload) {
    state.selectedPackage = payload;
  },
  [types.GET_TRANSACTION_LIST](state, payload) {
    state.transactionList = payload;
  },
  [types.SET_GET_TEAM_ERROR](state, payload) {
    state.getTeamStatus = payload;
  },
  [types.SET_BUY_LICENSE_ERROR](state, payload) {
    state.buyLicenseStatus = payload;
  },
  [types.GET_TRANSACTION_LIST_ERROR](state, payload) {
    state.transactionListStatus = payload;
  }
};
