<template>
  <div></div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    }
  }
};
</script>
