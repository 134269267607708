export const SET_FORM = "SET_FORM";
export const SET_FORMS = "SET_FORMS";
export const SET_ARCHIVE_FORMS = "SET_ARCHIVE_FORMS";
export const SET_PUBLISHED_FORM = "SET_PUBLISHED_FORM";
export const SET_SELECTED_VERSION = "SET_SELECTED_VERSION";
export const SET_CREATE_FORM_ERROR = "SET_CREATE_FORM_ERROR";
export const SET_GET_FORM_ERROR = "SET_GET_FORM_ERROR";
export const SET_FORM_ERROR = "SET_FORM_ERROR";
export const SET_IS_FORM_CHANGED = "SET_IS_FORM_CHANGED";
export const SET_RESPONSE = "SET_RESPONSE";
export const SET_SHOW_FORM_LIMIT_WARNING = "SET_SHOW_FORM_LIMIT_WARNING";
export const SET_ACTIVE_STEP_GUIDE = "SET_ACTIVE_STEP_GUIDE";
export const ADD_NEW_FORM = "ADD_NEW_FORM";
export const ADD_NEW_FORMS = "ADD_NEW_FORMS";
export const DELETE_FORM = "DELETE_FORM";
export const DELETE_FORMS = "DELETE_FORMS";
