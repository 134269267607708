import auth from "./auth";
import profile from "./profile";
import workspace from "./workspace";
import form from "./form";
import team from "./team";

export default {
  auth,
  profile,
  workspace,
  form,
  team
};
