<template>
  <div class="justify-content-center d-flex start-btn">
    <b-button
      :style="{ backgroundColor: design.colors.buttonColor }"
      v-if="node.data.btnText"
      style="min-width: 148px;"
      ><span :style="{ color: design.colors.buttonTextColor }">{{
        node.data.btnText
      }}</span></b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  }
};
</script>
