export default fields => {
  const fromToMappings = fields.reduce((acc, val) => {
    for (let element of val.logicJumps) {
      const map = [];
      map[0] = val.id;
      map[1] = element.to;
      acc.push(map);
    }

    return acc;
  }, []);

  let firstFormFieldId;
  for (let field of fields) {
    const foundMap = fromToMappings.find(([, to]) => to === field.id);
    if (!foundMap) {
      firstFormFieldId = field.id;
      break;
    }
  }

  return firstFormFieldId;
};
