import logicJumpExpressions from "@/constants/logicJumpExpressions";

const createLogicJump = (to, expressionString = null, enabled = false) => ({
  expressionString,
  to,
  enabled
});

export const createDefaultLogicJump = to =>
  createLogicJump(to, logicJumpExpressions.DEFAULT, true);

export default createLogicJump;
