import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import * as types from "./types";

export default {
  clearProfileStatus: context => {
    context.commit(types.CLEAR_PROFILE_STATUS);
  },
  getProfile: async context => {
    try {
      const profile = await API.get(API_NAME, "/profile");
      context.commit(types.SET_DATA, profile);
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  createProfile: async (context, params) => {
    try {
      const profile = await API.post(API_NAME, "/profile", {
        body: params
      });
      context.commit(types.SET_DATA, profile);
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  createWorkspace: async (context, { teamId, workspaceName, vm }) => {
    try {
      const body = { name: workspaceName };
      await API.post(API_NAME, `/teams/${teamId}/create-workspace`, { body });
      vm.$toast.success(vm.$t("workspaceAdded"));
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  createTeam: async (
    context,
    { username, teamName, teamDescription, email, vm }
  ) => {
    try {
      const body = { username, teamName, teamDescription, email };
      await API.post(API_NAME, `/teams/create`, { body });
      context.dispatch("getProfile");
      vm.$toast.success(vm.$t("teamCreated"));
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  firstSetup: async (context, { teamName, workspaceName, vm }) => {
    try {
      const body = { teamName, workspaceName };
      await API.post(API_NAME, `/teams/first-setup`, { body });
      vm.$toast.success(vm.$t("firstSetupSuccess"));
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  renameWorkspace: async (
    context,
    { teamId, workspaceId, workspaceName, vm }
  ) => {
    try {
      const body = { name: workspaceName };
      await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/rename`,
        { body }
      );
      vm.$toast.success(vm.$t("workspaceRenamed"));
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  deleteWorkspace: async (context, { teamId, workspaceId, vm }) => {
    try {
      await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/delete`
      );
      vm.$toast.success(vm.$t("workspaceDeleted"));
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  setLanguage: async (context, { value, vm }) => {
    try {
      await API.post(API_NAME, "/profile/language", {
        body: { value }
      });
      vm.$toast.success(vm.$t("languageSaved"));
      context.dispatch("getProfile");
    } catch (err) {
      context.commit(types.SET_PROFILE_ERROR, err);
    }
  },
  clearProfile: context => {
    context.commit(types.CLEAR_PROFILE);
  }
};
