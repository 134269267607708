<template>
  <b-row>
    <b-col md="12">
      <flowy-drag-handle class="grabbable h-100">
        <b-card
          @click="nodeClick"
          class="w-100 h-100 d-flex justify-content-between"
          :style="{ backgroundColor: design.colors.backgroundColor }"
        >
          <div class="d-flex justify-content-center">
            <icon name="drag-horizontal" />
          </div>
          <div
            class="d-flex justify-content-center align-items-center flex-column content-parrent"
          >
            <div class="content-wrapper">
              <div
                v-if="
                  type !== formFieldTypesQuiz.MULTISELECT_QUIZ &&
                    type !== formFieldTypesQuiz.TRUE_FALSE
                "
                class="d-flex flex-column content"
                :class="[
                  { 'align-items-center': type === formFieldTypes.START_PAGE }
                ]"
              >
                <h4 :style="{ color: design.colors.titleColor }">
                  {{ title }}
                  <span v-if="!title">{{ $t("insertTitle") }}</span>
                </h4>
                <div
                  v-if="description"
                  class="description"
                  :style="{ color: design.colors.descriptionColor }"
                  v-html="description"
                ></div>
                <div
                  v-if="!description && !title"
                  class="d-flex flex-column content align-items-center"
                >
                  <span class="description">{{ $t("insertDesc") }}</span>
                </div>
              </div>
              <div class="d-flex flex-column content align-items-center" v-else>
                <h4 v-html="description"></h4>
              </div>
              <component
                class="w-100"
                v-bind:is="type"
                :node="node"
                :design="design"
              ></component>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-end">
            <b-button variant="light" size="sm" @click.stop="remove()"
              ><b-icon-trash font-scale="1.5"
            /></b-button>
          </div>
        </b-card>
      </flowy-drag-handle>
    </b-col>
  </b-row>
</template>

<i18n src="@/locales/common/form-field-type-names.json"></i18n>
<i18n>
{
  "en": {
    "insertTitle": "Eg: Welcome to our survey form...",
    "insertDesc": "Eg: This form is to collect your feedback about our latest product..."
  },
  "id": {
    "insertTitle": "Misalnya: Selamat datang di formulir survei kami...",
    "insertDesc": "Misalnya: Formulir ini untuk mengumpulkan tanggapan Anda tentang produk terbaru kami..."
  }
}
</i18n>

<script>
import formFieldTypeNames from "@/constants/formFieldTypeNames";
import formFieldTypes from "@/constants/formFieldTypes";
import formFieldTypesQuiz from "@/constants/formFieldTypesQuiz";
import { mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import START_PAGE from "../FormEditor/FormStartPage";
import EMAIL from "../FormEditor/FormEmail";
import TEL from "../FormEditor/FormTelephone";
import SCALE from "../FormEditor/FormScale";
import LONG_TEXT from "../FormEditor/FormLongText";
import SHORT_TEXT from "../FormEditor/FormShortText";
import DROPDOWN from "../FormEditor/FormDropdown";
import DATE from "../FormEditor/FormDate";
import STATEMENT from "../FormEditor/FormBase";
import MULTISELECT from "../FormEditor/FormMultiple";
import NUMBER from "../FormEditor/FormNumber";
import FILE_UPLOAD from "../FormEditor/FileUpload";
import MULTISELECT_QUIZ from "../FormEditor/FormMultipleQuiz";
import TRUE_FALSE from "../FormEditor/FormTrueFalse";
import { EventBus } from "@/helpers/EventBus";

export default {
  components: {
    START_PAGE,
    EMAIL,
    TEL,
    SCALE,
    LONG_TEXT,
    SHORT_TEXT,
    DROPDOWN,
    DATE,
    STATEMENT,
    MULTISELECT,
    NUMBER,
    FILE_UPLOAD,
    MULTISELECT_QUIZ,
    TRUE_FALSE
  },
  data() {
    return {
      design: {},
      formFieldTypeNames,
      formFieldTypes,
      formFieldTypesQuiz
    };
  },
  props: {
    type: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
    node: {
      type: Object
    },
    remove: {
      type: Function
    }
  },
  computed: {
    ...mapGetters("form", ["form", "forms", "versions", "selectedVersion"])
  },
  methods: {
    nodeClick() {
      EventBus.$emit("node-click", this.node.id);
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        const form = cloneDeep(this.form);
        if (form) {
          this.design = form.design;
        }
      }
    }
  }
};
</script>

<style scoped>
.content-parrent {
  min-height: 263px;
}

.content-wrapper {
  max-width: 600px;
  width: 100%;
}

.description {
  width: 100%;
  margin-bottom: 8px;
}
</style>
