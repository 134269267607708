<template>
  <b-modal no-stacking :id="modalId" class="upgrade-modal" hide-footer>
    <template #modal-header></template>
    <template #default>
      <div>
        <transition name="fade-left">
          <div
            v-if="!isNextStepActive"
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <span class="title-custom">{{
              $t("subscriptionModal.choosePlan")
            }}</span>
            <span class="text-description">
              {{ $t("subscriptionModal.cancel") }}
            </span>
            <div class="button-wrapper">
              <b-button-group>
                <b-button
                  pill
                  :variant="duration === 'yearly' ? 'primary' : 'light'"
                  @click="setPerYear()"
                  >{{ $t("subscriptionModal.perYear") }}</b-button
                >
                <b-button
                  class="ml-1"
                  pill
                  :disabled="selectedTeam.licenseDuration === 'yearly'"
                  :variant="duration === 'monthly' ? 'primary' : 'light'"
                  @click="setPerMonth()"
                  >{{ $t("subscriptionModal.perMonth") }}</b-button
                >
              </b-button-group>
            </div>
            <div class="d-flex flex-row mt-4">
              <b-card class="subscription-card p-1">
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="sub-title-custom">Premium</span>
                  <span v-if="duration === 'yearly'" class="price"
                    >IDR 420.000</span
                  >
                  <span v-if="duration === 'monthly'" class="price"
                    >IDR 525.000</span
                  >
                  <span v-if="duration === 'yearly'" class="text-description">{{
                    $t("subscriptionModal.perMonthBill")
                  }}</span>
                  <span
                    v-if="duration === 'monthly'"
                    class="text-description"
                    >{{ $t("subscriptionModal.perMonth") }}</span
                  >
                </div>
                <div
                  style="height: 340px"
                  class="mt-4 d-flex flex-column justify-content-between"
                >
                  <div>
                    <span>{{ $t("subscriptionModal.premiumPlans") }}</span>
                    <div class="d-flex flex-row mt-1">
                      <icon name="checklist" />
                      <span class="ml-2"
                        >{{ $t("subscriptionModal.premiumDesc") }} <br />{{
                          $t("subscriptionModal.premiumDesc2")
                        }}</span
                      >
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.premiumDesc3")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc4")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc5")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc6")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc7")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc8")
                      }}</span>
                    </div>
                  </div>
                  <b-button
                    @click="upgradeToPremium()"
                    :variant="
                      selectedTeam.license === 'PROFESSIONAL' ||
                      selectedTeam.license === 'PREMIUM'
                        ? 'primary'
                        : 'light'
                    "
                    :disabled="
                      selectedTeam.license === 'PREMIUM' &&
                        selectedTeam.licenseDuration === duration
                    "
                    block
                  >
                    <span v-if="selectedTeam.license !== 'PREMIUM'">{{
                      $t("subscriptionModal.upgradePremium")
                    }}</span>
                    <span
                      v-if="
                        selectedTeam.license === 'PREMIUM' &&
                          selectedTeam.licenseDuration === duration
                      "
                      >{{ $t("subscriptionModal.currentPlan") }}</span
                    >
                    <span
                      v-if="
                        selectedTeam.license === 'PREMIUM' &&
                          selectedTeam.licenseDuration === 'monthly' &&
                          duration === 'yearly'
                      "
                      >Tingkatkan Paket</span
                    >
                  </b-button>
                </div>
              </b-card>

              <b-card
                v-if="selectedTeam.license !== 'PREMIUM'"
                :class="
                  selectedTeam.license !== 'PREMIUM' &&
                  selectedTeam.license !== 'PROFESSIONAL'
                    ? 'subs-card-professional-default'
                    : 'subs-card-professional'
                "
              >
                <template
                  v-if="
                    selectedTeam.license !== 'PREMIUM' &&
                      selectedTeam.license !== 'PROFESSIONAL'
                  "
                  #header
                >
                  <h5 class="mb-0">
                    {{ $t("subscriptionModal.mostPopular") }}
                  </h5>
                </template>
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="sub-title-custom">Professional</span>
                  <span v-if="duration === 'yearly'" class="price"
                    >IDR 270.000</span
                  >
                  <span v-if="duration === 'monthly'" class="price"
                    >IDR 375.000</span
                  >
                  <span v-if="duration === 'yearly'" class="text-description">{{
                    $t("subscriptionModal.perMonthBill")
                  }}</span>
                  <span
                    v-if="duration === 'monthly'"
                    class="text-description"
                    >{{ $t("subscriptionModal.perMonth") }}</span
                  >
                </div>

                <div
                  style="height: 340px"
                  class="mt-4 d-flex flex-column justify-content-between"
                >
                  <div>
                    <span>{{ $t("subscriptionModal.professionalPlans") }}</span>
                    <div class="d-flex flex-row mt-1">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.professionalDesc")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.professionalDesc2")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc4")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc5")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc6")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc7")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc8")
                      }}</span>
                    </div>
                  </div>
                  <b-button
                    :variant="
                      selectedTeam.license !== 'PROFESSIONAL' &&
                      selectedTeam.license !== 'PREMIUM'
                        ? 'primary'
                        : 'light'
                    "
                    @click="upgradeToProfessional()"
                    :disabled="
                      selectedTeam.license === 'PROFESSIONAL' &&
                        selectedTeam.licenseDuration === duration
                    "
                    block
                  >
                    <span v-if="selectedTeam.license !== 'PROFESSIONAL'">
                      {{ $t("subscriptionModal.upgradeProfesional") }}
                    </span>
                    <span
                      v-if="
                        selectedTeam.license === 'PROFESSIONAL' &&
                          selectedTeam.licenseDuration === duration
                      "
                      >{{ $t("subscriptionModal.currentPlan") }}
                    </span>
                    <span
                      v-if="
                        selectedTeam.license === 'PROFESSIONAL' &&
                          selectedTeam.licenseDuration === 'monthly' &&
                          duration === 'yearly'
                      "
                      >Tingkatkan Paket
                    </span>
                  </b-button>
                </div>
              </b-card>
              <b-card
                v-if="
                  selectedTeam.license !== 'PROFESSIONAL' &&
                    selectedTeam.license !== 'PREMIUM'
                "
                class="subscription-card p-1"
              >
                <div
                  class="d-flex flex-column justify-content-center align-items-center"
                >
                  <span class="sub-title-custom">Essentials</span>
                  <span v-if="duration === 'yearly'" class="price"
                    >IDR 180.000</span
                  >
                  <span v-if="duration === 'monthly'" class="price"
                    >IDR 225.000</span
                  >
                  <span v-if="duration === 'yearly'" class="text-description">{{
                    $t("subscriptionModal.perMonthBill")
                  }}</span>
                  <span
                    v-if="duration === 'monthly'"
                    class="text-description"
                    >{{ $t("subscriptionModal.perMonth") }}</span
                  >
                </div>

                <div
                  style="height: 340px"
                  class="mt-4 d-flex flex-column justify-content-between"
                >
                  <div>
                    <span>{{ $t("subscriptionModal.essentialsPlans") }}</span>
                    <div class="d-flex flex-row mt-1">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.essentialDesc")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.essentialDesc2")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc4")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc5")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc6")
                      }}</span>
                    </div>
                    <div class="d-flex flex-row">
                      <icon name="checklist" />
                      <span class="ml-2">{{
                        $t("subscriptionModal.desc7")
                      }}</span>
                    </div>
                  </div>
                  <b-button
                    @click="upgradeToEssentials()"
                    variant="light"
                    :disabled="
                      selectedTeam.license === 'ESSENTIALS' &&
                        selectedTeam.licenseDuration === duration
                    "
                    block
                  >
                    <span v-if="selectedTeam.license !== 'ESSENTIALS'">{{
                      $t("subscriptionModal.upgradeEssentials")
                    }}</span>
                    <span
                      v-if="
                        selectedTeam.license === 'ESSENTIALS' &&
                          selectedTeam.licenseDuration === duration
                      "
                      >{{ $t("subscriptionModal.currentPlan") }}</span
                    >
                    <span
                      v-if="
                        selectedTeam.license === 'ESSENTIALS' &&
                          selectedTeam.licenseDuration === 'monthly' &&
                          duration === 'yearly'
                      "
                      >Tingkatkan Paket
                    </span>
                  </b-button>
                </div>
              </b-card>
            </div>
            <span class="mt-5">
              {{ $t("subscriptionModal.customPlan") }}
              <b-link
                class="font-weight-bold"
                href="mailto:hello@survein.com"
                >{{ $t("subscriptionModal.contact") }}</b-link
              >
            </span>
          </div>
        </transition>

        <transition name="fade-right">
          <div v-if="isNextStepActive">
            <div
              class="mb-5 d-flex flex-column justify-content-center align-items-center"
            >
              <span
                v-if="selectedTeam.license !== 'PREMIUM'"
                class="title-custom"
              >
                {{ $t("payment.paymentTitle") }}
              </span>
              <span v-else class="title-custom">
                {{ $t("payment.addOn") }}
              </span>
              <span class="text-description">
                {{ $t("payment.paymentDesc") }}
              </span>
            </div>

            <b-row style="width: 1000px" class="m-auto">
              <b-col class="d-flex flex-column mt-4">
                <div
                  v-if="
                    selectedTeam.license === 'PREMIUM' &&
                      selectedTeam.licenseDuration === 'yearly' &&
                      !renew
                  "
                >
                  <span class="title-card">Add on Responses</span>
                  <b-form-select
                    v-model="selectedPackage"
                    @change="paymentAmount()"
                    :options="responseOptions"
                    style="width: 440px"
                    class="mt-3"
                  ></b-form-select>
                </div>

                <div v-else>
                  <span class="title-card">{{
                    $t("payment.chooseBilling")
                  }}</span>
                  <b-card
                    style="width: 440px; height:104px"
                    class="mt-3 card-border"
                  >
                    <b-form-group>
                      <b-form-radio-group v-model="duration" plain>
                        <b-form-radio value="yearly" @change="paymentAmount()">
                          <div class="ml-2">
                            <span class="font-weight-bold">{{
                              $t("subscriptionModal.perYear")
                            }}</span>
                            <br />
                            <span
                              >IDR {{ pricing.yearly }} /
                              {{ $t("payment.month") }}</span
                            >
                          </div>
                        </b-form-radio>
                        <b-form-radio
                          value="monthly"
                          class="ml-4"
                          @change="paymentAmount()"
                          :disabled="isDisabledButton"
                        >
                          <div class="ml-2">
                            <span class="font-weight-bold">{{
                              $t("subscriptionModal.perMonth")
                            }}</span>
                            <br />
                            <span>
                              IDR {{ pricing.monthly }} /
                              {{ $t("payment.month") }}
                            </span>
                          </div>
                        </b-form-radio>
                      </b-form-radio-group>
                    </b-form-group>
                  </b-card>
                </div>

                <div class="mt-5">
                  <span class="title-card">{{
                    $t("payment.choosePaymentMethod")
                  }}</span>
                  <b-card style="width:440px" class="card-border mt-3">
                    <b-form-group
                      label="Virtual Account"
                      label-class="font-weight-bold"
                    >
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="M2"
                        plain
                        class="d-flex align-items-center payment-border"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/mandiri.png" width="40px" />
                          <span class="ml-2">Mandiri Virtual Account</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="BT"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/permata_bank.png" width="40px" />
                          <span class="ml-2">Permata Bank Virtual Account</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="B1"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/cimb_niaga.png" width="40px" />
                          <span class="ml-2">CIMB Niaga Virtual Account</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="I1"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/bni.png" />
                          <span class="ml-2">BNI Virtual Account</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="VA"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/maybank.png" />
                          <span class="ml-2">Maybank Virtual Account</span>
                        </div>
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group
                      label="e-Wallet"
                      label-class="font-weight-bold"
                      class="payment-border mt-4"
                    >
                      <!-- <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="OV"
                        plain
                        class="d-flex align-items-center"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <icon name="ovo" />
                          <span class="ml-2">OVO</span>
                        </div>
                      </b-form-radio> -->
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="LA"
                        plain
                        class="d-flex align-items-center"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/link_aja.png" width="40px" />
                          <span class="ml-2">LinkAja Apps</span>
                        </div>
                      </b-form-radio>
                    </b-form-group>
                    <!-- <b-form-group
                      label="Instant Payment"
                      label-class="font-weight-bold"
                      class="payment-border mt-4"
                    >
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="BK"
                        plain
                        class="d-flex align-items-center"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/bca_klik_pay.png" />
                          <span class="ml-2">BCA KlikPay</span>
                        </div>
                      </b-form-radio>
                    </b-form-group> -->

                    <b-form-group
                      label="Bank Transfer"
                      label-class="font-weight-bold"
                      class="payment-border mt-4"
                    >
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="A1"
                        plain
                        class="d-flex align-items-center payment-border"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <icon name="atmBersama" />
                          <span class="ml-2">ATM Bersama</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="AG"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img
                            src="@/assets/bank_artha_graha.png"
                            width="40px"
                          />
                          <span class="ml-2">Bank Artha Graha</span>
                        </div>
                      </b-form-radio>
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="S1"
                        plain
                        class="d-flex align-items-center payment-border mt-3"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img
                            src="@/assets/bank_sahabat_sampoerna.png"
                            width="40px"
                          />
                          <span class="ml-2">Bank Sahabat Sampoerna</span>
                        </div>
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group
                      label="Retail"
                      label-class="font-weight-bold"
                      class="mt-4"
                    >
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="FT"
                        plain
                        class="d-flex align-items-center payment-border "
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <img src="@/assets/alfamart.png" width="40px" />
                          <img
                            src="@/assets/pegadaian.png"
                            width="40px"
                            class="ml-2"
                          />
                          <img
                            src="@/assets/kantor_pos.png"
                            width="40px"
                            class="ml-2"
                          />
                        </div>
                        <span class="ml-2"
                          >(Alfamart, Pegadaian, Kantor Pos)</span
                        >
                      </b-form-radio>
                    </b-form-group>
                    <b-form-group
                      label="Credit Card"
                      label-class="font-weight-bold"
                      class="mt-4"
                    >
                      <b-form-radio
                        v-model="selectedPaymentMethod"
                        value="VC"
                        plain
                        class="d-flex align-items-center"
                      >
                        <div
                          class="d-flex flex-row align-items-center mt-1 ml-1"
                        >
                          <icon name="mastercard" />
                          <icon name="visa" class="ml-2" />
                          <span class="ml-2">Credit or debit card</span>
                        </div>
                      </b-form-radio>
                    </b-form-group>
                  </b-card>
                </div>
              </b-col>
              <b-col class="d-flex align-items-start flex-column mt-4">
                <div
                  style="width: 400px"
                  class="d-flex justify-content-between align-items-start"
                >
                  <span class="title-card">{{
                    $t("payment.orderSummary")
                  }}</span>
                  <b-link
                    v-if="
                      prorated ||
                        (selectedTeam.license !== 'PREMIUM' &&
                          selectedTeam.licenseDuration !== 'yearly')
                    "
                    @click="changePlan()"
                    >{{ $t("payment.changePlan") }}</b-link
                  >
                </div>
                <b-card
                  style="width: 400px"
                  class="card-border mt-3 bold-border"
                >
                  <div
                    class="d-flex justify-content-between flex-row payment-border"
                  >
                    <b-form-input
                      style="width: 279px"
                      :placeholder="$t('payment.redeemCode')"
                      v-model="code"
                    ></b-form-input>
                    <b-button variant="primary">{{
                      $t("payment.apply")
                    }}</b-button>
                  </div>
                  <div
                    class="d-flex justify-content-between flex-row payment-border mt-4"
                  >
                    <span
                      style="width:200px"
                      v-if="selectedTeam.license !== 'PREMIUM'"
                    >
                      {{ selectedPackage }} <br />
                      <span
                        class="text-disclaimer"
                        v-if="duration === 'yearly' && !prorated && !renew"
                        >(12 months)</span
                      >
                    </span>
                    <span
                      v-if="
                        selectedTeam.license === 'PREMIUM' &&
                          selectedTeam.licenseDuration === 'yearly'
                      "
                    >
                      <p
                        v-html="
                          $t('payment.addOnResponses', {
                            response: selectedResponses
                          })
                        "
                      ></p>
                    </span>
                    <span>{{
                      redeem
                        ? $t("payment.free")
                        : "IDR " + totalAmount.toLocaleString("id-ID")
                    }}</span>
                  </div>
                  <div class="mt-4 mb-2 justify-content-between d-flex">
                    <span class="title-card">{{
                      $t("payment.totalPrice")
                    }}</span>
                    <span class="title-card">{{
                      redeem
                        ? $t("payment.free")
                        : "IDR " + totalAmount.toLocaleString("id-ID")
                    }}</span>
                  </div>
                  <b-button
                    @click="makeRequest()"
                    :disabled="selectedPaymentMethod === '' && !redeem"
                    class="mt-3"
                    variant="primary"
                    block
                    >{{
                      redeem ? $t("payment.continue") : $t("payment.upgrade")
                    }}</b-button
                  >
                </b-card>
                <span
                  v-if="!prorated"
                  style="width: 400px; color: #5E6674;"
                  class="mt-3"
                >
                  {{ $t("payment.billedReminder") }}
                </span>
                <span
                  v-if="prorated"
                  style="width: 400px; color: #5E6674;"
                  class="mt-3"
                >
                  <p
                    v-html="
                      $t('payment.prorateReminder', {
                        validUntil
                      })
                    "
                  ></p>
                </span>
              </b-col>
            </b-row>
          </div>
        </transition>
      </div>
    </template>
  </b-modal>
</template>

<i18n>
{
  "en": {
    "subscriptionModal": {
      "choosePlan": "Choose a plan that works for you",
      "cancel": "Cancel at any time, whether you pay per year or per month.",
      "perYear": "Per Year",
      "perMonth": "Per Month",
      "perMonthBill" : "Per month (billed yearly)",
      "premiumPlans" : "In Premium plans, you will get:",
      "premiumDesc" : "10,000 responses per month",
      "premiumDesc2" : "(up to 1,000 responses per 15,000 IDR available)",
      "premiumDesc3" : "10 Logic Jumps",
      "professionalPlans" : "In Professional plans, you will get:",
      "professionalDesc" : "5,000 responses per month",
      "professionalDesc2" : "5 Logic Jumps",
      "essentialsPlans" : "In Essentials plans, you will get:",
      "essentialDesc" : "1,000 responses per month",
      "essentialDesc2" : "3 Logic Jumps",
      "desc4" : "Unlimited forms",
      "desc5" : "Unlimited questions",
      "desc6" : "Respondent email notifications",
      "desc7" : "Schedule a close date",
      "desc8" : "Removal of Survein Logo",
      "upgradePremium" : "Upgrade to Premium",
      "upgradeProfesional" : "Upgrade to Professional",
      "upgradeEssentials" : "Upgrade to Essentials",
      "mostPopular": "MOST POPULAR",
      "customPlan": "Looking for a custom plan?",
      "contact" : "Contact our sales team",
      "planUpgraded": "You’re plan has been upgraded",
      "currentPlan": "Current Plan",
      "continueCurrentPlan": "Continue Current Plan",
      "changeToEssentials": "Change to Essentials",
      "changeToProfessional": "Change to Professional",
      "changeToPremium": "Change to Premium",
      "packageUpgraded": "Package successfully upgraded",
      "packageNotUpgraded": "Package not upgraded, please try again",
      "redeemSucceed": "Promo code applied"
    },
    "payment": {
      "paymentTitle": "You’re almost there",
      "paymentDesc": "Choose payment method that you are convenient with.",
      "chooseBilling": "Choose your billing cycle",
      "choosePaymentMethod": "Choose your payment method",
      "month": "month",
      "orderSummary": "Order Summary",
      "changePlan": "Change plan",
      "totalPrice": "Total price",
      "upgrade": "Proceed to Pay",
      "continue": "Proceed",
      "billedReminder": "We will help to remind you before it’s end, so that you can keep using Survein premium features.",
      "redeemCode": "Redeem your code",
      "apply": "Apply",
      "addOn": "Add on your plan",
      "addOnResponses": "Add on {response} responses per <br> month",
      "prorateReminder": "This is <b>prorated</b> amount for upgrade plan with your current billing period. Your new plan will be valid until <br><b>{validUntil}</b>",
      "invalidCode": "Promo code invalid",
      "free": "FREE"
    }
  },
  "id": {
    "subscriptionModal": {
      "choosePlan": "Pilih paket yang sesuai untuk Anda",
      "cancel": "Batalkan kapan saja, baik Anda membayar per tahun atau per bulan.",
      "perYear": "Per Tahun",
      "perMonth": "Per Bulan",
      "perMonthBill" : "Per bulan (tagihan setiap tahun)",
      "premiumPlans" : "Dalam paket Premium, Anda akan mendapatkan:",
      "premiumDesc" : "10.000 tanggapan per bulan",
      "premiumDesc2" : "(hingga 1.000 tanggapan per 15.000 IDR tersedia)",
      "premiumDesc3" : "10 Lompatan Logika",
      "professionalPlans" : "Dalam paket Profesional, Anda akan mendapatkan:",
      "professionalDesc" : "5.000 tanggapan per bulan",
      "professionalDesc2" : "5 Lompatan Logika",
      "essentialsPlans" : "Dalam paket Essentials, Anda akan mendapatkan:",
      "essentialDesc" : "1,000 tanggapan per bulan",
      "essentialDesc2" : "3 Lompatan Logika",
      "desc4" : "Formulir tidak terbatas",
      "desc5" : "Pertanyaan tak terbatas",
      "desc6" : "Pemberitahuan email responden",
      "desc7" : "Jadwalkan tanggal tutup",
      "desc8" : "Penghapusan Logo Survein",
      "upgradePremium" : "Tingkatkan ke Premium",
      "upgradeProfesional" : "Tingkatkan ke Professional",
      "upgradeEssentials" : "Tingkatkan ke Essentials",
      "mostPopular": "PALING POPULER",
      "customPlan": "Mencari paket khusus?",
      "contact" : "Hubungi tim penjualan kami",
      "planUpgraded": "Paket Anda telah ditingkatkan",
      "currentPlan": "Paket saat ini",
      "continueCurrentPlan": "Lanjutkan Paket",
      "changeToEssentials": "Ubah ke Essentials",
      "changeToProfessional": "Ubah ke Professional",
      "changeToPremium": "Ubah ke Premium",
      "packageUpgraded": "Berhasil meningkatkan paket",
      "packageNotUpgraded": "Paket gagal di tingkatkan, silahkan coba kembali",
      "redeemSucceed": "Berhasil klaim promo"
    },
    "payment": {
      "paymentTitle": "Kamu hampir selesai",
      "paymentDesc": "Pilih metode pembayaran yang Anda sukai.",
      "chooseBilling": "Pilih siklus penagihan Anda",
      "choosePaymentMethod": "Pilih metode pembayaran Anda",
      "month": "bulan",
      "orderSummary": "Ringkasan Pesanan",
      "changePlan": "Ubah paket",
      "totalPrice": "Total harga",
      "upgrade": "Lanjutkan Pembayaran",
      "continue": "Lanjutkan",
      "billedReminder": "Kami akan mengingatkan Anda sebelum paket berakhir, agar Anda dapat terus menggunakan fitur premium Survein.",
      "redeemCode": "Tukarkan kode Anda",
      "apply": "Pakai",
      "addOn": "Tambahkan paket anda",
      "addOnResponses": "Tambahkan {response} tanggapan <br> per bulan",
      "prorateReminder": "Ini adalah jumlah <b>prorata</b> untuk paket peningkatan dengan periode penagihan Anda saat ini. Paket baru Anda akan berlaku sampai <b>{validUntil}</b>",
      "invalidCode": "Promo tidak berlaku",
      "free": "GRATIS"
    }
  }
}
</i18n>

<script>
import { mapGetters, mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { addDays, differenceInDays } from "date-fns";
import { delay } from "lodash";

export default {
  props: {
    modalId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      duration: "yearly",
      orderId: "",
      isNextStepActive: false,
      selectedPackage: "",
      selectedPaymentMethod: "",
      responseOptions: [
        {
          value: "Premium add on 1000 responses",
          text: "1.000 responses per month"
        },
        {
          value: "Premium add on 2000 responses",
          text: "2.000 responses per month"
        },
        {
          value: "Premium add on 3000 responses",
          text: "3.000 responses per month"
        },
        {
          value: "Premium add on 4000 responses",
          text: "4.000 responses per month"
        },
        {
          value: "Premium add on 5000 responses",
          text: "5.000 responses per month"
        }
      ],
      prorated: false,
      renew: false,
      totalAmount: "0",
      package: "",
      code: "",
      redeem: false
    };
  },
  mounted() {
    this.orderId = uuidv4();
    if (this.selectedTeam) {
      if (this.selectedTeam.license === "FREE") {
        this.duration = "yearly";
      }
    }
  },
  methods: {
    ...mapActions("team", ["buyLicense", "redeemPromo"]),
    ...mapActions("workspace", ["getForms"]),

    redeemCode() {
      if (this.code.toLowerCase() !== "cobainsurvein") {
        this.$toast.warning(this.$t("payment.invalidCode"));
        return;
      }
      this.$toast.success(this.$t("subscriptionModal.redeemSucceed"));
      this.selectedPackage = "Premium monthly subscription";
      this.totalAmount = 0;
      this.redeem = true;
    },

    setPerYear() {
      this.duration = "yearly";
    },

    setPerMonth() {
      this.duration = "monthly";
    },

    setPackageRenew() {
      if (
        this.selectedTeam.license === "ESSENTIALS" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Essentials yearly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "ESSENTIALS" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Essentials monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "ESSENTIALS" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Essentials monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "ESSENTIALS" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Essentials yearly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PROFESSIONAL" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Professional yearly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PROFESSIONAL" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Professional monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PROFESSIONAL" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Professional monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PROFESSIONAL" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Professional yearly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PREMIUM" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Premium yearly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PREMIUM" &&
        this.selectedTeam.licenseDuration === "yearly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Premium monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PREMIUM" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "monthly"
      ) {
        this.selectedPackage = "Premium monthly subscription renew";
        this.paymentAmount();
      }
      if (
        this.selectedTeam.license === "PREMIUM" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Premium yearly subscription renew";
        this.paymentAmount();
      }
    },

    showUpgradeModal(isNext) {
      this.isNextStepActive = isNext;
      this.renew = false;
      this.duration = this.selectedTeam.licenseDuration;
      if (this.selectedTeam.license === "FREE") {
        this.duration = "yearly";
      }
      this.$bvModal.show("subscription-upgrade");
    },

    showRenewModal(isNext) {
      this.isNextStepActive = isNext;
      this.renew = true;
      this.setPackageRenew();
      this.$bvModal.show("subscription-renew");
    },

    hideUpgradeModal() {
      this.isNextStepActive = false;
      this.$bvModal.hide("subscription-upgrade");
    },

    hideRenewModal() {
      this.$bvModal.hide("subscription-renew");
    },

    async makeRequest() {
      // if (this.redeem) {
      //   await this.redeemPromo({
      //     payload: {
      //       teamId: this.team.id,
      //       code: this.code,
      //     },
      //     vm: this,
      //   });

      //   return;
      // }

      // if (this.isEssentialsMonthlySelected) {
      //   await this.redeemPromo({
      //     payload: {
      //       teamId: this.team.id,
      //       code: "essentials-monthly",
      //     },
      //     vm: this,
      //   });

      //   return;
      // }

      if (!this.selectedPaymentMethod || this.selectedPaymentMethod === "") {
        return;
      }
      const url = await this.buyLicense({
        paymentAmount: this.totalAmount,
        productDetails: this.selectedPackage,
        email: this.authenticatedUser.attributes.email,
        additionalParam: this.selectedTeam.pk,
        paymentMethod: this.selectedPaymentMethod,
        merchantUserInfo: this.authenticatedUser.attributes.email,
        customerVaName: this.username
      });
      window.open(url, "_blank");
      this.hideUpgradeModal();
      if (this.renew) {
        this.hideRenewModal();
      }
    },
    changePlan() {
      this.selectedPackage = "";
      this.package = "";
      this.isNextStepActive = false;
    },
    upgradeToPremium() {
      this.package = "PREMIUM";
      if (
        this.selectedTeam.license === "PREMIUM" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Premium monthly to Premium yearly";
        this.prorated = true;
        this.paymentAmount();
      }
      if (this.selectedTeam.license === "PROFESSIONAL") {
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "monthly"
        ) {
          this.selectedPackage = "Professional monthly to Premium monthly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Professional monthly to Premium yearly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "yearly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Professional yearly to Premium yearly";
          this.prorated = true;
          this.paymentAmount();
        }
      }
      if (this.selectedTeam.license === "ESSENTIALS") {
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "monthly"
        ) {
          this.selectedPackage = "Essentials monthly to Premium monthly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Essentials monthly to Premium yearly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "yearly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Essentials yearly to Premium yearly";
          this.prorated = true;
          this.paymentAmount();
        }
      }
      if (this.selectedTeam.license === "FREE") {
        if (this.duration === "monthly") {
          this.selectedPackage = "Premium monthly subscription";
          this.paymentAmount();
        }
        if (this.duration === "yearly") {
          this.selectedPackage = "Premium yearly subscription";
          this.paymentAmount();
        }
      }
      this.isNextStepActive = true;
    },
    upgradeToProfessional() {
      this.package = "PROFESSIONAL";
      if (
        this.selectedTeam.license === "PROFESSIONAL" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Professional monthly to Professional yearly";
        this.prorated = true;
        this.paymentAmount();
      }
      if (this.selectedTeam.license === "ESSENTIALS") {
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "monthly"
        ) {
          this.selectedPackage = "Essentials monthly to Professional monthly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "monthly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Essentials monthly to Professional yearly";
          this.prorated = true;
          this.paymentAmount();
        }
        if (
          this.selectedTeam.licenseDuration === "yearly" &&
          this.duration === "yearly"
        ) {
          this.selectedPackage = "Essentials yearly to Professional yearly";
          this.prorated = true;
          this.paymentAmount();
        }
      }
      if (this.selectedTeam.license === "FREE") {
        if (this.duration === "monthly") {
          this.selectedPackage = "Professional monthly subscription";
          this.paymentAmount();
        }
        if (this.duration === "yearly") {
          this.selectedPackage = "Professional yearly subscription";
          this.paymentAmount();
        }
      }
      this.isNextStepActive = true;
    },
    upgradeToEssentials() {
      this.package = "ESSENTIALS";
      if (
        this.selectedTeam.license === "ESSENTIALS" &&
        this.selectedTeam.licenseDuration === "monthly" &&
        this.duration === "yearly"
      ) {
        this.selectedPackage = "Essentials monthly to Essentials yearly";
        this.paymentAmount();
        this.prorated = true;
      }
      if (this.selectedTeam.license === "FREE") {
        if (this.duration === "monthly") {
          this.selectedPackage = "Essentials monthly subscription";
          this.paymentAmount();
        }
        if (this.duration === "yearly") {
          this.selectedPackage = "Essentials yearly subscription";
          this.paymentAmount();
        }
      }
      this.isNextStepActive = true;
    },

    paymentAmount() {
      let value = "";
      let remainingCredit = "";
      let daysGoesOn = "";

      switch (this.selectedPackage) {
        case "Premium monthly subscription":
          if (this.duration === "monthly") {
            value = 525000;
          }
          break;

        case "Premium yearly subscription":
          if (this.duration === "yearly") {
            value = 5048000;
          }
          break;

        case "Professional monthly subscription":
          if (this.duration === "monthly") {
            value = 375000;
          }
          break;

        case "Professional yearly subscription":
          if (this.duration === "yearly") {
            value = 3240000;
          }
          break;

        case "Essentials monthly subscription":
          if (this.duration === "monthly") {
            value = 225000;
          }
          break;

        case "Essentials yearly subscription":
          if (this.duration === "yearly") {
            value = 2160000;
          }
          break;

        case "Premium monthly subscription renew":
          value = 525000;
          break;

        case "Premium yearly subscription renew":
          value = 5040000;
          break;

        case "Professional monthly subscription renew":
          value = 375000;
          break;

        case "Professional yearly subscription renew":
          value = 3240000;
          break;

        case "Essentials monthly subscription renew":
          value = 225000;
          break;

        case "Essentials yearly subscription renew":
          value = 2160000;
          break;

        case "Essentials monthly to Essentials yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            7500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 6000 - remainingCredit;
          break;

        case "Essentials monthly to Professional monthly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            7500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (30 - daysGoesOn) * 12500 - remainingCredit;
          break;

        case "Essentials monthly to Professional yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            7500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 9000 - remainingCredit;
          break;

        case "Essentials yearly to Professional yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            6000;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 9000 - remainingCredit;
          break;

        case "Essentials monthly to Premium monthly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            7500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (30 - daysGoesOn) * 17500 - remainingCredit;
          break;

        case "Essentials monthly to Premium yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            7500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 14000 - remainingCredit;
          break;

        case "Essentials yearly to Premium yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            6000;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 14000 - remainingCredit;
          break;

        case "Professional monthly to Professional yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            12500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 9000 - remainingCredit;
          break;

        case "Professional monthly to Premium monthly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            12500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value =
            (differenceInDays(
              this.selectedTeam.licenseValidUntil,
              this.selectedTeam.licenseStartDate
            ) -
              daysGoesOn) *
              17500 -
            remainingCredit;
          break;

        case "Professional monthly to Premium yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            12500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 14000 - remainingCredit;
          break;

        case "Professional yearly to Premium yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            9000;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 14000 - remainingCredit;
          break;

        case "Premium monthly to Premium yearly":
          remainingCredit =
            differenceInDays(this.selectedTeam.licenseValidUntil, Date.now()) *
            17500;
          daysGoesOn = differenceInDays(
            Date.now(),
            this.selectedTeam.licenseStartDate
          );
          value = (360 - daysGoesOn) * 14000 - remainingCredit;
          break;

        case "Premium add on 1000 responses":
          value = 15000;
          break;

        case "Premium add on 2000 responses":
          value = 30000;
          break;

        case "Premium add on 3000 responses":
          value = 45000;
          break;

        case "Premium add on 4000 responses":
          value = 60000;
          break;

        case "Premium add on 5000 responses":
          value = 75000;
          break;

        default:
          break;
      }
      this.totalAmount = value;
    }
  },
  computed: {
    ...mapGetters("profile", ["language", "username", "team"]),
    ...mapGetters("auth", ["authenticatedUser"]),
    ...mapGetters("profile", ["workspaces", "team"]),
    ...mapGetters("team", ["selectedTeam"]),

    isDisabledButton() {
      let disable = false;
      if (
        this.selectedTeam.license === this.package &&
        this.selectedTeam.licenseDuration === "monthly"
      ) {
        disable = true;
      }
      if (this.renew) {
        disable = false;
      }
      if (this.selectedTeam.licenseDuration === "yearly") {
        disable = true;
      }
      return disable;
    },

    pricing() {
      let price = {
        yearly: "",
        monthly: ""
      };
      switch (this.selectedPackage) {
        case "Premium monthly subscription":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Premium yearly subscription":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Professional monthly subscription":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Professional yearly subscription":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Essentials monthly subscription":
          price.monthly = "225.000";
          price.yearly = "180.000";
          break;

        case "Essentials yearly subscription":
          price.monthly = "225.000";
          price.yearly = "180.000";
          break;

        case "Premium monthly subscription renew":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Premium yearly subscription renew":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Professional monthly subscription renew":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Professional yearly subscription renew":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Essentials monthly subscription renew":
          price.monthly = "225.000";
          price.yearly = "180.000";
          break;

        case "Essentials yearly subscription renew":
          price.monthly = "225.000";
          price.yearly = "180.000";
          break;

        case "Essentials monthly to Essentials yearly":
          price.monthly = "225.000";
          price.yearly = "180.000";
          break;

        case "Essentials monthly to Professional monthly":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Essentials monthly to Professional yearly":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Essentials yearly to Professional yearly":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Essentials monthly to Premium monthly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Essentials monthly to Premium yearly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Essentials yearly to Premium yearly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Professional monthly to Professional yearly":
          price.monthly = "375.000";
          price.yearly = "270.000";
          break;

        case "Professional monthly to Premium monthly":
          price.monthly = "525.000";
          price.yearly = "525.000";
          break;

        case "Professional monthly to Premium yearly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Professional yearly to Premium yearly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        case "Premium monthly to Premium yearly":
          price.monthly = "525.000";
          price.yearly = "420.000";
          break;

        default:
          break;
      }
      return price;
    },

    selectedResponses() {
      let value = null;
      if (this.selectedPackage === "Premium add on 1000 responses") {
        value = 1000;
      }
      if (this.selectedPackage === "Premium add on 2000 responses") {
        value = 2000;
      }
      if (this.selectedPackage === "Premium add on 3000 responses") {
        value = 3000;
      }
      if (this.selectedPackage === "Premium add on 4000 responses") {
        value = 4000;
      }
      if (this.selectedPackage === "Premium add on 5000 responses") {
        value = 5000;
      }
      return value;
    },

    daysBeforeExpired() {
      return differenceInDays(this.selectedTeam.licenseValidUntil, Date.now());
    },

    validUntil() {
      let date = "";
      if (this.selectedTeam.licenseDuration === this.duration) {
        return moment(this.selectedTeam.licenseValidUntil).format(
          "DD MMM YYYY"
        );
      }

      if (this.duration === "yearly") {
        date = addDays(
          new Date(this.selectedTeam.licenseValidUntil),
          360 - differenceInDays(Date.now(), this.selectedTeam.licenseStartDate)
        ).toString();
      }

      if (this.duration === "monthly") {
        date = addDays(
          new Date(this.selectedTeam.licenseValidUntil),
          30 - differenceInDays(Date.now(), this.selectedTeam.licenseStartDate)
        ).toString();
      }
      return moment(date).format("DD MMM YYYY");
    }

    // isEssentialsMonthlySelected() {
    //   return this.selectedPackage === "Essentials monthly subscription";
    // },
  },
  watch: {
    selectedTeam: {
      immediate: true,
      handler() {
        this.$forceUpdate();
      }
    },
    duration: {
      immediate: true,
      handler() {
        if (this.package === "PREMIUM") {
          this.upgradeToPremium();
        }
        if (this.package === "PROFESSIONAL") {
          this.upgradeToProfessional();
        }
        if (this.package === "ESSENTIALS") {
          this.upgradeToEssentials();
        }
        if (this.renew === true) {
          this.setPackageRenew();
        }
      }
    },
    orderId: {
      immediate: true,
      handler() {
        delay(() => {
          if (this.selectedTeam) {
            this.duration = this.selectedTeam.licenseDuration;

            if (
              this.selectedTeam.license === "PREMIUM" &&
              this.selectedTeam.licenseDuration === "yearly"
            ) {
              this.isNextStepActive = true;
              this.selectedPackage = "Premium add on 1000 responses";
              this.paymentAmount();
            }
            if (this.selectedTeam.license === "FREE") {
              this.duration = "yearly";
            }
          }
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bold-border {
  border: 2px solid #166ed8 !important;
}

.title-custom {
  font-size: 32px;
  font-weight: 700;
  color: #1f2432;
}

.title-card {
  font-size: 20px;
  font-weight: 700;
}

.sub-title-custom {
  font-size: 24px;
  color: #1f2432;
}

.price {
  font-size: 40px;
  font-weight: 700;
  color: #1f2432;
}

.text-disclaimer {
  font-size: 16px;
  color: #5e6674;
}

.button-wrapper {
  width: 194px;
  height: 58px;
  background: #f4f5f6;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 64px;
}

.subscription-card {
  width: 316px;
  height: 533px;
  margin-right: 32px;
  margin-top: 40px;
  border-radius: 8px !important;
}

.subscription-card:last-child {
  margin-right: 0;
}

.subs-card-professional {
  width: 316px;
  height: 533px;
  margin-right: 32px;
  margin-top: 40px;
  border-radius: 8px !important;
}

.subs-card-professional-default {
  width: 316px;
  height: 569px;
  margin-right: 32px;
  margin-top: 4px;
  border-radius: 8px !important;
  border: 2px solid #166ed8;
}

.upgrade-button {
  position: relative;
  bottom: -48px;
}

.fade-left-enter {
  transform: translateX(-20px);
  opacity: 0;
}

.fade-left-enter-active {
  transition: all 0.5s ease;
}

.fade-left-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.fade-right-enter {
  transform: translateX(20px);
  opacity: 0;
}

.fade-right-enter-active {
  transition: all 0.5s ease;
}

.fade-right-enter-to {
  transform: translateX(0px);
  opacity: 1;
}

.payment-border {
  padding-bottom: 18px;
  border-bottom: 2px solid #e6e9ed;
}
</style>
