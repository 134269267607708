<template>
  <b-form-textarea
    placeholder="Write Your Answer Here..."
    rows="3"
  ></b-form-textarea>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    }
  }
};
</script>
