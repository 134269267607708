<template>
  <div id="app" class="h-100">
    <HeaderMainLayout>
      <template #header>
        <AppNavbar v-if="isAuthenticated" />
      </template>
      <router-view v-if="!isLoading" />
    </HeaderMainLayout>
  </div>
</template>

<script>
import AppNavbar from "@/components/AppNavbar";
import HeaderMainLayout from "@/layouts/HeaderMainLayout";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      isLoading: true
    };
  },
  components: {
    AppNavbar,
    HeaderMainLayout
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated", "rememberDevice"]),
    ...mapGetters("profile", ["hasProfiled", "language"])
  },
  async mounted() {
    this.isLoading = true;
    await this.currentAuthenticatedUser();
    if (this.isAuthenticated) {
      await this.getProfile();
      this.$router.replace({
        name: "Workspace"
      });
    }
    this.isLoading = false;
    if (this.hasProfiled) {
      this.$root.$i18n.locale = this.language;
    }
  },
  created() {
    const isRememberDevice = localStorage.getItem("rememberDevice");

    if (
      (window.performance.navigation &&
        window.performance.navigation.type != 1) ||
      !window.performance
        .getEntriesByType("navigation")
        .map(nav => nav.type)
        .includes("reload")
    ) {
      if (isRememberDevice === "false") {
        this.signOut();
      }
    }
  },
  methods: {
    ...mapActions("auth", ["currentAuthenticatedUser", "signOut"]),
    ...mapActions("profile", ["getProfile"])
  }
};
</script>

<style>
html,
body {
  height: 100vh;
}

#app {
  background-color: #f0f0f0;
}
</style>
