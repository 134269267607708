<template>
  <div>
    <b-container class="p-0">
      <div class="mt-2">
        <b-row no-gutters class="d-flex flex-row justify-content-center">
          <b-col cols="5" class="mb-2 mr-2">
            <div class="choice-option" style="background-color: #166ED8">
              <div>
                <b-form-checkbox
                  :checked="node.data.correctAnswer === 'a'"
                  name="correctAnswer"
                  class="float-left pl-0 position-absolute"
                  @change="toggleCorrectAnswer('a')"
                >
                </b-form-checkbox>
              </div>
              <div class="choice-label">
                <span :style="{ color: design.colors.buttonTextColor }">
                  True
                </span>
              </div>
            </div>
          </b-col>
          <b-col cols="5" class="mb-2">
            <div class="choice-option" style="background: #F7195A">
              <div>
                <b-form-checkbox
                  :checked="node.data.correctAnswer === 'b'"
                  name="multiple-quiz-option"
                  class="float-left pl-0 position-absolute"
                  @change="toggleCorrectAnswer('b')"
                >
                </b-form-checkbox>
              </div>
              <div class="choice-label">
                <span :style="{ color: design.colors.buttonTextColor }">
                  False
                </span>
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="mt-2 d-flex justify-content-center">
          <span class="text-description">{{ $t("markAnswer") }}</span>
        </div>
      </div>
    </b-container>
  </div>
</template>

<i18n>
{
  "en": {
    "formFieldSaved": "Form field saved",
    "markAnswer": "(Tick to mark the right answer)"
  },
  "id": {
    "formFieldSaved": "Bidang formulir disimpan",
    "markAnswer": "(Centang untuk menandai jawaban yang benar)"
  }
}
</i18n>

<script>
import FormBase from "./FormBase";
import { mapActions, mapGetters } from "vuex";
import { cloneDeep } from "lodash";

export default {
  extends: FormBase,
  props: {
    design: {
      type: Object
    }
  },
  computed: {
    ...mapGetters("form", ["form"])
  },
  methods: {
    ...mapActions("form", ["saveFormFields"]),

    async toggleCorrectAnswer(value) {
      const fields = cloneDeep(this.form.fields);

      const index = fields.indexOf(
        fields.find(field => field.id === this.node.data.id)
      );

      fields[index].correctAnswer = value;

      await this.saveFormFields({ fields, vm: this });
    }
  }
};
</script>

<style lang="scss" scoped>
.choice-option {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 139px;
  padding: 12px 12px;
  background-color: #dfeaf0;
  border-radius: 8px;
  margin-right: 8px;

  .bullet {
    position: absolute;
    min-width: 16px;
    width: 16px;
    height: 16px;
  }

  .choice-label {
    margin: auto;
    text-align: center;
  }
}

.label {
  width: 656px;
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
</style>
