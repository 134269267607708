export default {
  profile: state => state.data,
  username: state => state.data.username,
  language: state => state.data.language,
  avatarChar: state =>
    state.data.username ? state.data.username.slice(0, 2).toUpperCase() : "SV",
  teams: state =>
    state.data && state.data.teams.length ? state.data.teams : [],
  workspaces: state =>
    state.activeTeam
      ? state.activeTeam.workspaces
      : state.data.teams[0].workspaces,
  hasProfiled: state => state.hasProfiled,
  hasProfileStatus: state => !!state.profileStatus,
  profileStatus: state => state.profileStatus
};
