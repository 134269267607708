export default {
  START_PAGE: "Welcome screen",
  STATEMENT: "Statement",
  EMAIL: "Email",
  TEL: "Tel",
  SCALE: "Opinion scale",
  MULTISELECT: "Multiple choice",
  LONG_TEXT: "Long text",
  SHORT_TEXT: "Short text",
  DROPDOWN: "Dropdown",
  DATE: "Date",
  NUMBER: "Number",
  END_PAGE: "Thank you screen",
  FILE_UPLOAD: "File Upload",
  MULTISELECT_QUIZ: "Multiple choice",
  TRUE_FALSE: "True or False"
};
