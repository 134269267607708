import * as types from "./types";

export default {
  [types.SET_SELECTED_WORKSPACE_ID](state, payload) {
    state.selectedWorkspaceId = payload;
  },
  [types.SET_FORMS](state, payload) {
    state.forms = payload;
  },
  [types.SET_WORKSPACES](state, payload) {
    state.workspaces = payload;
  },
  [types.SET_GET_FORMS_ERROR](state, payload) {
    state.getFormStatus = payload;
  }
};
