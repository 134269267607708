var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","id":_vm.modalId,"no-stacking":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"mb-0 col-12 text-center"},[_vm._v(" "+_vm._s(_vm.$t("manageRespondentAccess"))+" ")])]},proxy:true},{key:"default",fn:function(){return [(_vm.modalId === 'set-close-response')?_c('div',{staticClass:"col-12 d-flex justify-content-between align-items-center p-0"},[_c('span',{staticStyle:{"color":"#A1AEB7"}},[_vm._v(_vm._s(_vm.$t("closeNow")))]),_c('b-form-radio',{staticClass:"p-0",attrs:{"disabled":""}})],1):_vm._e(),_c('div',{staticClass:"col-12 d-flex justify-content-between align-items-center p-0",class:_vm.modalId === 'set-close-response' ? 'mt-4' : ''},[_c('span',[_vm._v(_vm._s(_vm.$t("setResponseLimit")))]),_c('b-form-radio',{staticClass:"p-0",attrs:{"value":"responseLimit"},on:{"change":function($event){_vm.selectedDate = null}},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1),(_vm.selectedOption === 'responseLimit')?_c('div',{staticClass:"col-12 d-flex p-0 mt-2"},[_c('b-input-group',{attrs:{"append":_vm.$t('responses')}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Type the limit, eg: 100"},model:{value:(_vm.response),callback:function ($$v) {_vm.response=$$v},expression:"response"}})],1)],1):_vm._e(),_c('div',{staticClass:"col-12 d-flex justify-content-between align-items-center p-0 mt-4"},[_c('span',[_vm._v(_vm._s(_vm.$t("scheduleDate")))]),_c('b-form-radio',{staticClass:"p-0",attrs:{"value":"scheduleDate"},on:{"change":function($event){_vm.response = null}},model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1),(_vm.selectedOption === 'responseLimit' && _vm.response)?_c('div',{staticClass:"alert-primary"},[_c('span',{domProps:{"innerHTML":_vm._s(
          _vm.$t('formCloseByResponse', {
            selectedResponseLimit: _vm.response
          })
        )}})]):_vm._e(),(_vm.selectedOption === 'scheduleDate')?_c('div',[_c('div',{staticClass:"col-12 p-0 mt-2"},[_c('label',{attrs:{"for":"time-zone"}},[_vm._v(_vm._s(_vm.$t("timeZone")))]),_c('b-form-select',{attrs:{"id":"time-zone","options":_vm.timezoneList},model:{value:(_vm.selectedTimezone),callback:function ($$v) {_vm.selectedTimezone=$$v},expression:"selectedTimezone"}})],1),_c('div',{staticClass:"col-12 d-flex flex-row p-0 mt-2"},[_c('div',{staticClass:"col-6 p-0"},[_c('label',{attrs:{"for":"date-picker"}},[_vm._v(_vm._s(_vm.$t("closeDate")))]),_c('b-form-datepicker',{attrs:{"id":"date-picker"},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1),_c('div',{staticClass:"col-6 pl-3 pr-0"},[_c('label',{attrs:{"for":"close-time"}},[_vm._v(_vm._s(_vm.$t("closeTime")))]),_c('b-input-group',{attrs:{"id":"close-time"}},[_c('b-form-timepicker',{attrs:{"locale":"en"},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}})],1)],1)])]):_vm._e()]},proxy:true},{key:"modal-footer",fn:function(ref){
        var cancel = ref.cancel;
return [_c('div',{staticClass:"w-100"},[_c('b-button',{staticClass:"float-left",attrs:{"variant":"light"},on:{"click":_vm.removeIndicator}},[_vm._v(" "+_vm._s(_vm.$t("removeCloseIndicator"))+" ")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){_vm.setCloseForm({
            responseClosing:
              _vm.response === null ? _vm.response : parseInt(_vm.response, 10),
            dateClosing: _vm.buildDate()
          })}}},[_vm._v(" "+_vm._s(_vm.$t("save"))+" ")]),_c('b-button',{staticClass:"float-right mr-2",attrs:{"variant":"light"},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }