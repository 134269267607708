import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import { v4 as uuidv4 } from "uuid";
import * as types from "./types";
import findFirstFieldId from "@/helpers/findFirstFieldId";
import createLogicJump, {
  createDefaultLogicJump
} from "@/helpers/createLogicJump";
import cloneDeep from "lodash/cloneDeep";
import logicJumpExpressions from "@/constants/logicJumpExpressions";
import formVersionKeys from "@/constants/formVersionKeys";
import { startNodeId } from "@/constants/reservedNodeIds";

export default {
  createForm: async (context, { teamId, workspaceId, title, template }) => {
    const body = { title, template };
    try {
      const createdForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms`,
        { body }
      );
      context.commit(types.SET_FORM, ...createdForm);
      context.commit(types.SET_SELECTED_VERSION, formVersionKeys.DRAFT);
      context.commit(types.ADD_NEW_FORM, ...createdForm);
    } catch (err) {
      context.commit(types.SET_CREATE_FORM_ERROR, err);
    }
  },

  addNewForm: (context, { form }) => {
    context.commit(types.ADD_NEW_FORM, form);
  },

  deleteForm: (context, { formId, version }) => {
    context.commit(types.DELETE_FORM, { id: formId, version });
  },

  duplicateForm: async (context, { teamId, workspaceId, formId, title }) => {
    const body = { title };
    try {
      const createdForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/duplicate`,
        { body }
      );
      context.commit(types.SET_FORM, createdForm);
      context.commit(types.SET_SELECTED_VERSION, formVersionKeys.DRAFT);
    } catch (err) {
      context.commit(types.SET_CREATE_FORM_ERROR, err);
    }
  },

  renameForm: async (context, { teamId, workspaceId, formId, title }) => {
    const body = { title };
    try {
      const updatedForms = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/rename`,
        { body }
      );

      context.commit(types.DELETE_FORM, { id: formId, version: "DRAFT" });
      context.commit(
        types.ADD_NEW_FORM,
        updatedForms.find(form => form.version === "DRAFT")
      );
      context.commit(
        types.SET_FORM,
        updatedForms.find(form => form.version === "DRAFT")
      );

      if (updatedForms.length > 1) {
        context.commit(types.DELETE_FORM, { id: formId, version: "PUBLISHED" });
        context.commit(
          types.ADD_NEW_FORM,
          updatedForms.find(form => form.version === "PUBLISHED")
        );
        context.commit(
          types.SET_FORM,
          updatedForms.find(form => form.version === "PUBLISHED")
        );
      }
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  moveForm: async (
    context,
    { teamId, workspaceId, formId, newWorkspaceId }
  ) => {
    const body = { newWorkspaceId };
    try {
      const form = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/move`,
        { body }
      );
      context.commit(types.SET_FORM, form);
      context.commit(types.SET_SELECTED_VERSION, formVersionKeys.DRAFT);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  unarchiveForm: async (context, { teamId, formId, newWorkspaceId }) => {
    const body = { newWorkspaceId };
    try {
      const form = await API.post(
        API_NAME,
        `/teams/${teamId}/forms/${formId}/unarchive`,
        { body }
      );
      context.commit(types.SET_FORM, form);
      context.commit(types.SET_SELECTED_VERSION, formVersionKeys.DRAFT);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  archiveForm: async (context, { teamId, workspaceId, formId, vm }) => {
    try {
      await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/archive`
      );

      vm.$toast.success(vm.$t("formDeleted"));
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  getForm: async (context, { teamId, workspaceId, formId }) => {
    try {
      const forms = await API.get(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}`
      );
      context.commit(
        types.SET_FORM,
        forms.find(f => f.version === formVersionKeys.DRAFT)
      );
      context.commit(types.SET_SELECTED_VERSION, formVersionKeys.DRAFT);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  getFormResponse: async (context, { teamId, workspaceId, formId }) => {
    try {
      let result = await API.get(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/response`
      );

      let responses = [...result.Items];

      if (result.LastEvaluatedKey) {
        do {
          result = await API.get(
            API_NAME,
            `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/response?pk=${result.LastEvaluatedKey.pk}&sk=${result.LastEvaluatedKey.sk}`
          );
          responses = [...responses, ...result.Items];
        } while (result.LastEvaluatedKey);
      }

      context.commit(types.SET_RESPONSE, {
        ...result,
        Items: responses
      });
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  setAcceptingResponse: async (
    context,
    { teamId, workspaceId, formId, value, vm }
  ) => {
    try {
      await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/response/accepting`,
        { body: { value } }
      );

      vm.$toast.success(vm.$t("succeedSetAcceptingResponse"));

      const publishForm = cloneDeep(context.getters.form);

      // replace draft form fields with newly modified one
      publishForm.acceptingResponse = value;
      context.commit(types.SET_FORM, publishForm);
      context.commit(types.DELETE_FORM, {
        id: publishForm.id,
        version: "PUBLISHED"
      });
      context.commit(types.ADD_NEW_FORM, publishForm);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  setClosingForm: async (
    context,
    { teamId, workspaceId, formId, value, vm }
  ) => {
    try {
      const updatedForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/closingForm`,
        { body: { value } }
      );

      vm.$toast.success(vm.$t("succeedSetCloseForm"));

      context.dispatch("setIsFormChanged", true);
      context.commit(types.DELETE_FORM, { id: formId, version: "PUBLISHED" });
      context.commit(types.ADD_NEW_FORM, updatedForm);
      context.commit(types.SET_FORM, updatedForm);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  updateFormDesign: async (
    context,
    { teamId, workspaceId, formId, design, vm }
  ) => {
    try {
      const updatedForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/design`,
        { body: design }
      );
      vm.$toast.success(vm.$t("colorUpdated"));
      context.dispatch("setIsFormChanged", true);
      context.commit(types.DELETE_FORM, { id: formId, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, updatedForm);
      context.commit(types.SET_FORM, updatedForm);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  updateFormBackground: async (
    context,
    { teamId, workspaceId, formId, payload, vm }
  ) => {
    try {
      const updatedForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/background`,
        {
          body: payload
        }
      );

      vm.$toast.success(vm.$t("backgroundUpdated"));
      context.dispatch("setIsFormChanged", true);
      context.commit(types.DELETE_FORM, { id: formId, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, updatedForm);
      context.commit(types.SET_FORM, updatedForm);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  updateEndPage: async (context, { teamId, workspaceId, formId, endPage }) => {
    try {
      const updatedForm = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/endpage`,
        { body: endPage }
      );
      context.dispatch("setIsFormChanged", true);
      context.commit(types.DELETE_FORM, { id: formId, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, updatedForm);
      context.commit(types.SET_FORM, updatedForm);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  updateCustomUrl: async (context, { teamId, formId, customUrl, vm }) => {
    try {
      const updateCustomUrl = await API.post(
        API_NAME,
        `/teams/${teamId}/form/${formId}/custom-url`,
        { body: { customUrl } }
      );
      vm.$toast.success(vm.$t("linkChanged"));
      context.commit(types.SET_FORM, updateCustomUrl);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
      vm.$toast.error(vm.$t("linkError"));
    }
  },

  publishForm: async (context, { teamId, workspaceId, formId, vm }) => {
    try {
      const updatedForms = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/publish`
      );
      vm.$toast.success(vm.$t("formPublished"));
      context.dispatch("setIsFormChanged", false);
      context.commit(types.DELETE_FORMS, formId);
      context.commit(types.ADD_NEW_FORMS, updatedForms);
    } catch (err) {
      context.commit(types.SET_GET_FORM_ERROR, err);
    }
  },

  async removeFormField(context, { removedId }) {
    const { teamId, workspaceId, id: formId } = context.getters.form;
    const fields = context.getters.form.fields || [];
    const removedField = fields.find(field => field.id === removedId);

    // detach removed field from the tree
    {
      for (let field of fields) {
        const isParentField = field.logicJumps.some(
          jump => jump.to === removedId
        );
        if (isParentField) {
          const logicJumpsWithoutRemovedField = field.logicJumps.filter(
            jump => jump.to !== removedId
          );
          const hasDefaultLogicJump = logicJumpsWithoutRemovedField.some(
            jump => jump.expressionString === logicJumpExpressions.DEFAULT
          );
          const removedFieldLogicJumps = hasDefaultLogicJump
            ? removedField.logicJumps.map(jump => {
                if (jump.expressionString === logicJumpExpressions.DEFAULT) {
                  return createLogicJump(jump.to);
                } else {
                  return jump;
                }
              })
            : cloneDeep(removedField.logicJumps);

          field.logicJumps = logicJumpsWithoutRemovedField.concat(
            removedFieldLogicJumps
          );
          break;
        }
      }
    }

    const fieldsWithoutRemoved = fields.filter(field => field.id !== removedId);

    try {
      const { fields: modifiedFields } = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/fields`,
        { body: fieldsWithoutRemoved }
      );

      const draftForm = cloneDeep(context.getters.form);

      // replace draft form fields with newly modified one
      draftForm.fields = modifiedFields;
      context.commit(types.SET_FORM, draftForm);
      context.commit(types.DELETE_FORM, { id: draftForm.id, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, draftForm);
    } catch (err) {
      context.commit(types.SET_FORM_ERROR, err);
    }
  },

  async rearrangeFormField(context, { isInsert, draggedId, toId }) {
    const { teamId, workspaceId, id: formId } = context.getters.form;
    const fields = context.getters.form.fields || [];
    const firstNodeId = findFirstFieldId(fields);
    const draggedField = fields.find(field => field.id === draggedId);

    if (isInsert) {
      // detach dragged field from the tree
      {
        for (let field of fields) {
          const isParentField = field.logicJumps.some(
            jump => jump.to === draggedId
          );
          if (isParentField) {
            const logicJumpsWithoutDraggedField = field.logicJumps.filter(
              jump => jump.to !== draggedId
            );
            const hasDefaultLogicJump = logicJumpsWithoutDraggedField.some(
              jump => jump.expressionString === logicJumpExpressions.DEFAULT
            );
            const draggedFieldLogicJumps = hasDefaultLogicJump
              ? draggedField.logicJumps.map(jump => {
                  if (jump.expressionString === logicJumpExpressions.DEFAULT) {
                    return createDefaultLogicJump(jump.to);
                  } else {
                    return jump;
                  }
                })
              : cloneDeep(draggedField.logicJumps);

            field.logicJumps = logicJumpsWithoutDraggedField.concat(
              draggedFieldLogicJumps
            );
            break;
          }
        }
        draggedField.logicJumps = [];
      }

      // dragged to first node
      if (toId === startNodeId) {
        draggedField.logicJumps = [createDefaultLogicJump(firstNodeId)];
      } else {
        // get the logicJumps from parent
        // assign logicJumps for parent to point to dragged field
        for (let field of fields) {
          if (field.id === toId) {
            draggedField.logicJumps = cloneDeep(field.logicJumps);
            field.logicJumps = [createDefaultLogicJump(draggedId)];
            break;
          }
        }
      }
    } else {
      // detach dragged field from the tree
      {
        for (let field of fields) {
          const isParentField = field.logicJumps.some(
            jump => jump.to === draggedId
          );
          if (isParentField) {
            const logicJumpsWithoutDraggedField = field.logicJumps.filter(
              jump => jump.to !== draggedId
            );
            const hasDefaultLogicJump = logicJumpsWithoutDraggedField.some(
              jump => jump.expressionString === logicJumpExpressions.DEFAULT
            );
            const draggedFieldLogicJumps = hasDefaultLogicJump
              ? draggedField.logicJumps.map(jump => {
                  if (jump.expressionString === logicJumpExpressions.DEFAULT) {
                    return createDefaultLogicJump(jump.to);
                  } else {
                    return jump;
                  }
                })
              : draggedField.logicJumps;

            field.logicJumps = logicJumpsWithoutDraggedField.concat(
              draggedFieldLogicJumps
            );
            break;
          }
        }
        draggedField.logicJumps = [];
      }

      // assign logicJumps for parent to point to dragged field
      for (let field of fields) {
        if (field.id === toId) {
          field.logicJumps.push(createLogicJump(draggedId));
        }
      }
    }

    try {
      const { fields: modifiedFields } = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/fields`,
        { body: fields }
      );

      const draftForm = cloneDeep(context.getters.form);

      // replace draft form fields with newly modified one
      draftForm.fields = modifiedFields;
      context.commit(types.SET_FORM, draftForm);
      context.commit(types.DELETE_FORM, { id: draftForm.id, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, draftForm);
    } catch (err) {
      context.commit(types.SET_FORM_ERROR, err);
    }
  },
  saveFormField(context, { isNew, isInsert, parentId, value, isEditing }) {
    // const { teamId, workspaceId, id: formId } = context.getters.form(
    //   formVersionKeys.DRAFT
    // );
    const fields = context.getters.form.fields || [];

    // newly added node
    if (isNew) {
      const newNodeId = uuidv4();
      value.logicJumps = [];

      // if it is insert to the node tree, modify the sibling nodes first
      if (isInsert) {
        // if it is inserted as top node
        if (parentId === startNodeId) {
          const firstNodeId = findFirstFieldId(fields);
          if (firstNodeId) {
            value.logicJumps.push(createDefaultLogicJump(firstNodeId));
          }
        } else {
          // else get the logicJumps from parent
          // assign logicJumps for parent to point to added new node
          for (let field of fields) {
            if (field.id === parentId) {
              value.logicJumps = cloneDeep(field.logicJumps);
              field.logicJumps = [createDefaultLogicJump(newNodeId)];
            }
          }
        }
      } else {
        for (let field of fields) {
          if (field.id === parentId) {
            field.logicJumps.push(createLogicJump(newNodeId));
          }
        }
      }

      fields.push({
        id: newNodeId,
        parentId,
        ...value
      });
    } else if (isEditing) {
      const index = fields.findIndex(field => field.id === value.id);
      fields[index] = value;
    }

    const draftForm = cloneDeep(context.getters.form);

    // replace draft form fields with newly modified one
    draftForm.fields = fields;
    context.commit(types.SET_FORM, draftForm);

    // try {
    //   const { fields: modifiedFields } = await API.post(
    //     API_NAME,
    //     `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/fields`,
    //     { body: fields }
    //   );

    //   const forms = cloneDeep(context.getters.forms);
    //   const draftForm = forms.find((f) => f.version === formVersionKeys.DRAFT);

    //   // replace draft form fields with newly modified one
    //   draftForm.fields = modifiedFields;
    //   context.commit(types.SET_FORMS, forms);
    //   vm.$toast.success(vm.$t("formFieldSaved"));
    // } catch (err) {
    //   context.commit(types.SET_FORM_ERROR, err);
    // }
  },
  async saveFormFields(context, { fields, vm }) {
    const { teamId, workspaceId, id: formId } = context.getters.form;
    try {
      const { fields: modifiedFields } = await API.post(
        API_NAME,
        `/teams/${teamId}/workspaces/${workspaceId}/forms/${formId}/fields`,
        { body: fields }
      );

      const draftForm = cloneDeep(context.getters.form);

      // replace draft form fields with newly modified one
      draftForm.fields = modifiedFields;
      context.commit(types.SET_FORM, draftForm);
      context.commit(types.DELETE_FORM, { id: draftForm.id, version: "DRAFT" });
      context.commit(types.ADD_NEW_FORM, draftForm);

      vm.$toast.success(vm.$t("formFieldSaved"));

      context.dispatch("setIsFormChanged", true);
    } catch (err) {
      context.commit(types.SET_FORM_ERROR, err);
    }
  },
  setSelectedVersion: async (context, version) => {
    context.commit(types.SET_SELECTED_VERSION, version);
  },
  setIsFormChanged: async (context, value) => {
    context.commit(types.SET_IS_FORM_CHANGED, value);
  },
  setShowFormLimitWarning: (context, value) => {
    context.commit(types.SET_SHOW_FORM_LIMIT_WARNING, value);
  },
  setActiveStepGuide: (context, value) => {
    context.commit(types.SET_ACTIVE_STEP_GUIDE, value);
  },
  setSelectedForm: (context, { formId, version }) => {
    const form = context.state.forms.find(
      f => f.id === formId && f.version === version
    );
    context.commit(types.SET_FORM, form);
  },
  setForms: (context, value) => {
    context.commit(types.SET_FORMS, value);
  }
};
