<template>
  <div id="app-navbar">
    <b-navbar toggleable="lg" variant="light" class="pl-0">
      <div
        :class="
          activeStepGuide !== 3 && activeStepGuide !== null
            ? 'active-guide'
            : ''
        "
      ></div>
      <b-navbar-brand
        class="ml-3"
        v-if="isAuthenticated && !isSettingPage"
        :to="homeRoute"
      >
        <logo name="logo" />
      </b-navbar-brand>
      <b-nav>
        <b-nav-item
          v-if="this.$route.name === 'Settings'"
          @click="goToWorkspace"
        >
          <b-icon icon="arrow-left-short" />
          {{ $t("workspace") }}
        </b-nav-item>
      </b-nav>
      <b-nav>
        <b-nav-item
          @click="openRenameFormModal"
          v-if="showFormMenu"
          class="text-dark title"
        >
          {{ selectedForm ? selectedForm.title : "" }}
        </b-nav-item>
      </b-nav>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-nav v-if="showFormMenu" class="ml-auto align-items-center form-nav">
          <b-nav-item
            v-if="selectedForm && selectedForm.template === 'quiz'"
            @click.prevent="goToFormBuilderQuiz"
            :active="isQuizPage"
            >{{ $t("quiz") }}
          </b-nav-item>
          <b-nav-item
            v-else
            @click.prevent="goToFormBuilder"
            :active="isFormBuilder"
            >{{ $t("form") }}
          </b-nav-item>
          <b-nav-item @click.prevent="goToFormResponse" :active="isFormResponse"
            >{{ $t("responses") }}
          </b-nav-item>
          <b-nav-item @click.prevent="goToFormShare" :active="isFormShare"
            >{{ $t("share") }}
          </b-nav-item>
        </b-nav>
        <b-navbar-nav class="ml-auto align-items-center" align="right">
          <b-nav-item v-if="showFormMenu">
            <div
              class="d-flex justify-content-center align-items-center"
              @click="showPreviewForm()"
            >
              <b-icon icon="eye" font-scale="1.5"></b-icon>
            </div>
          </b-nav-item>
          <b-nav-item id="publish-button" v-if="showFormMenu">
            <b-button
              :style="showFormLimitWarning ? 'width: 95px' : ''"
              variant="primary"
              size="sm"
              @click="checkPublish()"
              :disabled="disablePublish"
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <span>{{ $t("publish") }}</span>
              <icon v-if="showFormLimitWarning" name="publish-warning"></icon>
            </b-button>
          </b-nav-item>
          <div v-if="showFormMenu" class="vertical-line"></div>
          <b-nav-item>
            <b-button
              :hidden="
                (selectedTeam && selectedTeam.license !== 'FREE') ||
                  !teams.length
              "
              @click="openUpgradeModal()"
              ref="btnShow"
              variant="success"
              size="sm"
            >
              {{ $t("upgrade") }}
            </b-button>
          </b-nav-item>
          <template v-if="hasProfiled">
            <b-nav-item-dropdown right no-caret>
              <template #button-content>
                <b-avatar
                  variant="warning"
                  :text="avatarChar"
                  size="30"
                ></b-avatar>
                {{ username }}
                <b-icon
                  class="caret"
                  icon="caret-down-fill"
                  font-scale="0.85"
                />
              </template>
              <b-dropdown-item @click="onSettingClick">{{
                $t("settings")
              }}</b-dropdown-item>
              <b-dropdown-item @click="onSignOutClick">{{
                $t("logout")
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- Modal -->
    <b-modal centered id="publish-modal" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("prepareForPublish") }}</h5>
      </template>
      <template #default>
        <div class="d-flex flex-column justify-content-center">
          <div class="image">
            <icon name="ready-to-publish" />
          </div>
          <span class="text-center" v-if="findForm(formId, 'PUBLISHED')">{{
            $t("confirmRepublish")
          }}</span>
          <span class="text-center" v-else>{{ $t("confirmPublish") }}</span>
        </div>
      </template>
      <template #modal-footer>
        <div
          class="d-flex justify-content-between align-items-center"
          :class="findForm(formId, 'PUBLISHED') ? 'w-100' : ''"
        >
          <div
            v-if="findForm(formId, 'PUBLISHED')"
            class="d-flex align-items-center"
            @click="openCloseFormModal()"
          >
            <span
              class="float-left cursor-pointer"
              :style="responseLimitSet ? 'color:#1DAB32' : 'color:#166ED8;'"
            >
              {{ $t("scheduledFormClose") }}
            </span>
            <icon
              class="ml-1"
              v-if="responseLimitSet"
              name="checklist-green"
            ></icon>
          </div>
          <b-button
            v-b-modal.published-modal
            class="float-right"
            variant="primary"
            @click="formPublish"
          >
            {{ $t("publish") }}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal centered id="published-modal" ref="published-modal" hide-footer>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("shareForm") }}</h5>
      </template>
      <template #default>
        <div
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div class="image">
            <icon name="publish-success" />
          </div>
          <span class="d-flex justify-content-center mb-2">{{
            $t("shareFormDesc")
          }}</span>
          <b-input-group class="col-md-9 m-auto">
            <b-form-input disabled type="text" :value="url" />
            <input type="text" :value="url" ref="mylink" class="copylink" />
            <b-input-group-append>
              <b-button @click="copyToClipboard()" variant="primary">{{
                $t("copy")
              }}</b-button>
            </b-input-group-append>
          </b-input-group>
          <div class="share-links mt-3 mb-2">
            <div class="share-btn" v-b-modal.qrshare-modal>
              <icon name="qr" />
            </div>
            <div class="share-btn" @click="goToFbLink()">
              <icon name="fb" />
            </div>
            <div class="share-btn" @click="goToWaLink()">
              <icon name="wa" />
            </div>
            <div class="share-btn">
              <icon name="share" />
            </div>
          </div>
        </div>
      </template>
    </b-modal>

    <b-modal size="sm" centered id="qrshare-modal">
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("qrCodeForm") }}</h5>
      </template>
      <template #default>
        <qr-code class="mb-3" :text="url"></qr-code>
        <span class="d-flex justify-content-center m-auto">{{
          $t("shareQrDescription")
        }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          {{ $t("return") }}
        </b-button>
        <b-button size="md" variant="primary" @click="downloadQR()">
          {{ $t("downloadQR") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-if="!isHomeRoute"
      :id="displayMode === 'desktop' ? 'preview-form' : 'preview-form-mobile'"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default>
        <iframe
          :class="
            displayMode === 'desktop'
              ? 'preview-wrapper-display'
              : 'preview-wrapper-mobile'
          "
          :src="urlPreview"
        ></iframe>
      </template>
    </b-modal>

    <b-modal id="rename-form-navbar" centered>
      <template #modal-header>
        <h5 class="mb-0 col-12 text-center">{{ $t("renameForm") }}</h5>
      </template>
      <b-form class="mt-3">
        <b-form-group :label="$t('enterNewName')">
          <b-form-input v-model="updateFormTitle" type="text" required />
          <b-form-invalid-feedback>{{
            $t("formNameErrMsg")
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button size="md" variant="primary" @click="formRename()">
          {{ $t("save") }}
        </b-button>
      </template>
    </b-modal>

    <div v-if="showNavigationButton" class="navigation-button">
      <div
        style="border-radius: 8px"
        variant="light"
        class="mb-2 cursor-pointer button-not-selected"
        @click="closePreviewModal()"
      >
        <icon name="close-button" />
      </div>
      <div
        style="border-top-left-radius: 8px; border-top-right-radius: 8px"
        :class="
          displayMode === 'desktop' ? 'button-selected' : 'button-not-selected'
        "
        @click="showDisplayMode()"
      >
        <b-icon
          font-scale="1.5"
          :variant="displayMode === 'desktop' ? 'primary' : 'dark'"
          icon="display"
        />
      </div>
      <div
        style="border-bottom-left-radius: 8px; border-bottom-right-radius: 8px"
        :class="
          displayMode === 'mobile' ? 'button-selected' : 'button-not-selected'
        "
        @click="showMobileMode()"
      >
        <b-icon
          font-scale="1.5"
          :variant="displayMode === 'mobile' ? 'primary' : 'dark'"
          icon="phone"
        />
      </div>
    </div>

    <SubscriptionModal :modalId="'subscription-upgrade'" ref="modalComponent" />
    <CloseFormModal
      v-if="form"
      :formId="this.$route.params.formId || ''"
      :teamId="this.$route.params.teamId || ''"
      :workspaceId="this.$route.params.workspaceId || ''"
      :modalId="'set-close-response2'"
      ref="closeModal"
    />
  </div>
</template>

<i18n>
{
  "en": {
    "settings": "Settings",
    "upgrade": "Upgrade",
    "logout": "Logout",
    "prepareForPublish": "Prepare for publish",
    "confirmPublish": "You’re about to publish your form. Make sure no typo and spelling errors.",
    "confirmRepublish": "By republish this form, the current form will be updated automatically and no longer exist. Do you still want to continue?",
    "publish": "Publish",
    "shareForm": "Share Form",
    "shareFormDesc": "Remember to spread the form to public",
    "qrCodeForm": "QR Code Form",
    "shareQrDescription": "Show the code to your friends to fill the form, or screenshot it.",
    "return": "Return",
    "downloadQR": "Download QR",
    "copy": "Copy",
    "workspace": "Workspace",
    "form": "Form",
    "quiz": "Quiz",
    "responses": "Responses",
    "publish": "Publish",
    "upgrade": "Upgrade",
    "share": "Share",
    "linkCopied": "Link is copied",
    "formPublished": "Form is published",
    "setResponseLimit": "Set a response limit",
    "scheduleDate": "Schedule a close date",
    "formCloseByResponse": "Your form will be close when reach to <b>{selectedResponseLimit} responses</b>",
    "removeCloseIndicator": "Remove close indicator",
    "set": "Set",
    "cancel": "Cancel",
    "closeDate": "Close date",
    "closeTime": "Close time",
    "timeZone": "Time zone",
    "scheduleFormClose": "Schedule Form Close",
    "scheduledFormClose": "Scheduled form close",
    "renameForm": "Rename Form",
    "formRenamed": "Form renamed",
    "enterNewName": "Please enter a new name for the form",
    "save": "Save",
    "formNameErrMsg": "Name is required."
  },
  "id": {
    "settings": "Pengaturan",
    "logout": "Keluar",
    "prepareForPublish": "Bersiap untuk dipublikasi",
    "confirmPublish": "Anda akan mempublikasikan formulir survei Anda. Pastikan seluruhnya sudah tepat.",
    "confirmRepublish": "Dengan menerbitkan kembali, formulir survei sebelumnya akan diperbarui secara otomatis. Apakah Anda masih ingin melanjutkan?",
    "shareForm": "Bagikan formulir survei",
    "shareFormDesc": "Jangan lupa sebarkan formulir survei Anda",
    "qrCodeForm": "Kode QR Formulir Survei",
    "shareQrDescription": "Pindai Kode QR untuk mulai mengisi survei",
    "return": "Kembali",
    "downloadQR": "Unduh kode QR",
    "copy": "Salin",
    "workspace": "Ruang Kerja",
    "form": "Formulir",
    "quiz": "Kuis",
    "responses": "Tanggapan",
    "publish": "Terbitkan",
    "upgrade": "Tingkatkan",
    "share": "Bagikan",
    "linkCopied": "Tautan disalin",
    "formPublished": "Formulir diterbikan",
    "setResponseLimit": "Tetapkan batas tanggapan",
    "scheduleDate": "Jadwalkan tanggal tutup",
    "formCloseByResponse": "Formulir Anda akan ditutup ketika mencapai <b>{selectedResponseLimit} tanggapan</b>",
    "removeCloseIndicator": "Hapus Indikator",
    "set": "Atur",
    "cancel": "Batal",
    "closeDate": "Tanggal tutup",
    "closeTime": "Waktu tutup",
    "timeZone": "Zona waktu",
    "scheduleFormClose": "Jadwal Tutup Formulir",
    "scheduledFormClose": "Jadwalkan Tutup Formulir",
    "renameForm": "Ubah nama form",
    "formRenamed": "Berhasil mengubah nama form",
    "enterNewName": "Silakan masukkan nama baru untuk formulir",
    "save": "Simpan",
    "formNameErrMsg": "Nama harus diisi."
  }
}
</i18n>

<script>
import { mapGetters, mapActions } from "vuex";
import { cloneDeep } from "lodash";
import VueQRCodeComponent from "vue-qrcode-component";
import download from "downloadjs";
import SubscriptionModal from "./common/SubscriptionModal";
import CloseFormModal from "./common/CloseFormModal";

export default {
  components: {
    "qr-code": VueQRCodeComponent,
    SubscriptionModal,
    CloseFormModal
  },

  data() {
    return {
      selectedForm: {},
      displayMode: "desktop",
      showNavigationButton: false,
      isLoading: true,
      selectedOption: "responseLimit",
      selectedResponseLimit: null,
      selectedTimePeriod: "",
      selectedDate: "",
      selectedTimezone: "",
      closeTime: "",
      timePeriod: [
        { value: "am", text: "AM" },
        { value: "pm", text: "PM" }
      ],
      responseLimitSet: false,
      updateFormTitle: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters("profile", [
      "hasProfiled",
      "avatarChar",
      "teams",
      "username"
    ]),
    ...mapGetters("form", [
      "form",
      "forms",
      "versions",
      "selectedVersion",
      "isFormChanged",
      "showFormLimitWarning",
      "findForm",
      "activeStepGuide"
    ]),
    ...mapGetters("team", ["selectedTeam"]),
    homeRoute() {
      if (this.hasProfiled) {
        return { name: "Workspace" };
      } else {
        return { name: "Login" };
      }
    },
    isHomeRoute() {
      let homeRoute = false;
      if (this.$route.name === "Workspace") {
        homeRoute = true;
      }
      return homeRoute;
    },
    showFormMenu() {
      return (
        this.$route.name === "FormBuilder" ||
        this.$route.name === "FormSummary" ||
        this.$route.name === "FormDetails" ||
        this.$route.name === "FormShare" ||
        this.$route.name === "FormBuilderQuiz" ||
        this.$route.name === "FormLeaderboard"
      );
    },
    isSettingPage() {
      return this.$route.name === "Settings";
    },
    isQuizPage() {
      return this.$route.name === "FormBuilderQuiz";
    },
    isFormBuilder() {
      return this.$route.name === "FormBuilder";
    },
    isFormResponse() {
      return (
        this.$route.name === "FormSummary" ||
        this.$route.name === "FormDetails" ||
        this.$route.name === "FormLeaderboard"
      );
    },
    isFormShare() {
      return this.$route.name === "FormShare";
    },
    disablePublish() {
      return this.versions.includes("PUBLISHED") && !this.isFormChanged;
    },
    url() {
      return this.form.customUrl
        ? `${process.env.VUE_APP_FORM_ENDPOINT}/${this.form.customUrl}`
        : `${process.env.VUE_APP_FORM_ENDPOINT}/${this.formId}`;
    },
    urlPreview() {
      return `${process.env.VUE_APP_FORM_ENDPOINT}/${
        this.selectedForm.id
      }?preview=true${this.displayMode === "mobile" ? "&mobile=true" : ""}`;
    },
    logicJumpsCount() {
      let count = 0;
      for (const field of this.selectedForm.fields) {
        if (field.logics && field.logics.length > 0) {
          count++;
        }
      }
      return count;
    },
    logicJumpsLimit() {
      let limit = 0;
      if (this.selectedTeam) {
        if (this.selectedTeam.license === "FREE") {
          limit = 1;
        }
        if (this.selectedTeam.license === "ESSENTIALS") {
          limit = 3;
        }
        if (this.selectedTeam.license === "PROFESSIONAL") {
          limit = 5;
        }
        if (this.selectedTeam.license === "PREMIUM") {
          limit = 10;
        }
      }
      return limit;
    },
    formId() {
      return this.$route.params.formId;
    }
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    ...mapActions("form", [
      "publishForm",
      "setShowFormLimitWarning",
      "setForms",
      "renameForm"
    ]),
    ...mapActions("profile", ["clearProfile"]),
    ...mapActions("team", ["setTeam"]),
    onLanguageClick(languageId) {
      // set this.$root.$i18n instead of this.$i18n
      this.$root.$i18n.locale = languageId;
    },

    async onSignOutClick() {
      await this.signOut();
      await this.clearProfile();
      await this.setTeam(null);
      this.setForms([]);
    },

    goToWorkspace() {
      this.closePreviewModal();
      this.$router.push({ name: "Workspace" });
    },

    onSettingClick() {
      this.closePreviewModal();
      this.$router.push({ name: "Settings" });
    },

    goToFormBuilder() {
      this.closePreviewModal();
      if (this.$route.name === "FormBuilder") return;
      this.$router.replace({ name: "FormBuilder" });
    },

    goToFormBuilderQuiz() {
      this.closePreviewModal();
      if (this.$route.name === "FormBuilderQuiz") return;
      this.$router.replace({ name: "FormBuilderQuiz" });
    },

    goToFormResponse() {
      this.closePreviewModal();
      if (this.$route.name === "FormResponse") return;
      this.$router.replace({ name: "FormSummary" });
    },

    goToFormShare() {
      this.closePreviewModal();
      if (this.$route.name === "FormShare") return;
      this.$router.replace({ name: "FormShare" });
    },

    checkPublish() {
      if (
        (this.selectedForm.fields &&
          this.selectedForm.fields.length > 10 &&
          this.selectedTeam.license === "FREE") ||
        this.logicJumpsCount > this.logicJumpsLimit
      ) {
        this.setShowFormLimitWarning(true);
        return;
      }
      this.$bvModal.show("publish-modal");
    },

    async formPublish() {
      await this.publishForm({
        teamId: this.$route.params.teamId,
        workspaceId: this.$route.params.workspaceId,
        formId: this.$route.params.formId,
        vm: this
      });
    },

    async formRename() {
      await this.renameForm({
        teamId: this.$route.params.teamId,
        workspaceId: this.$route.params.workspaceId,
        formId: this.$route.params.formId,
        title: this.updateFormTitle
      });
      this.$bvModal.hide("rename-form-navbar");
      this.$toast.success(this.$t("formRenamed"));
      this.$emit("get-forms");
    },

    openRenameFormModal() {
      this.updateFormTitle = this.form.title;
      this.$bvModal.show("rename-form-navbar");
    },

    goToFbLink() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${this.url}`,
        "_blank"
      );
    },

    goToWaLink() {
      const LinkTextToShare =
        "https://api.whatsapp.com/send?text=" + encodeURIComponent(this.url);

      window.open(LinkTextToShare, "_blank");
    },

    copyToClipboard() {
      var Url = this.$refs.mylink;
      Url.select();
      document.execCommand("copy");
      this.$toast.success(this.$t("linkCopied"));
    },

    downloadQR() {
      var allImages = document.getElementsByTagName("img");
      var images = [];
      for (var i = 0, len = allImages.length; i < len; ++i) {
        if (allImages[i].alt == "Scan me!") {
          images.push(allImages[i]);
        }
      }

      download(images[0].currentSrc, "qr-code.png", "image/png");
    },
    openUpgradeModal() {
      this.closePreviewModal();
      this.$refs.modalComponent.showUpgradeModal();
    },
    openCloseFormModal() {
      this.$refs.closeModal.showCloseModal2();
    },
    showPreviewForm() {
      this.$bvModal.show("preview-form");
      this.showNavigationButton = true;
    },
    closePreviewModal() {
      this.$bvModal.hide("preview-form");
      this.$bvModal.hide("preview-form-mobile");
      this.showNavigationButton = false;
      this.displayMode = "desktop";
    },
    showDisplayMode() {
      this.displayMode = "desktop";
    },
    showMobileMode() {
      this.displayMode = "mobile";
    }
  },
  watch: {
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.selectedForm = cloneDeep(this.form);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 2;
}

.vertical-line {
  height: 40px;
  width: 1px;
  background-color: #dfeaf0;
  margin: auto 8px;

  @media screen and (max-width: 990px) {
    display: none;
  }
}

.navbar {
  padding: 0px 14px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075);
}

.nav-item {
  &.text-dark {
    a {
      color: #343a40;
    }
  }

  &.title {
    min-width: 100px;
  }
}

.form-nav {
  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      color: #343a40;
      height: 47px;
      &.active {
        color: #007bff;
        border-bottom: 4px solid #007bff;
      }
    }
  }
}

.image {
  width: 172px;
  height: 172px;
  margin: auto;
  margin-bottom: 16px;
}

.caret {
  position: relative;
  bottom: 2px;
}

.navigation-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 60px;
  right: 16px;
  z-index: 9999;
}

.button-not-selected {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #f4f5f6;
  color: black;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: #dbdee1;
  }
}

.button-selected {
  width: 48px;
  height: 48px;
  cursor: pointer;
  background-color: #e8f1fb;
  color: black;
  border: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);
  box-shadow: 4px 12px 30px -13px rgba(0, 0, 0, 0.3);
}

.preview-wrapper-display {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.preview-wrapper-mobile {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  width: 360px;
  height: 640px;
  border: none;
}

.copylink {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}

.active-guide {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4) !important;
  z-index: 9999;
}
</style>
