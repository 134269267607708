<template>
  <div class="wrapper">
    <icon name="upload" class="mb-3" />
    <span class="text-center">
      ({{ buildFileTypes() }} up to {{ mapFileSize() }})
    </span>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  },
  methods: {
    buildFileTypes() {
      let types = [];

      if (this.node.data.fileTypes.other) {
        types.push("file");
        return types.toString();
      }

      if (this.node.data.fileTypes.image) {
        types = [...types, "jpg", "jpeg", "png", "gif"];
      }

      if (this.node.data.fileTypes.document) {
        types = [...types, "pdf", "xls", "doc", "ppt"];
      }

      if (this.node.data.fileTypes.video) {
        types = [...types, "mp4", "mov"];
      }

      return types.toString().replace(/,/g, ", ");
    },
    mapFileSize() {
      switch (this.node.data.maxSize) {
        case 512000:
          return "500 KB";
        case 1048576:
          return "1 MB";
        case 5242880:
          return "5 MB";
        case 10485760:
          return "10 MB";
        default:
          return "500 KB";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 520px;
  height: 162px;
  background: #f4f5f7;
  border: 1px dashed #a1aeb7;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}
</style>
