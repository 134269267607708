<template>
  <div>
    <b-input-group>
      <b-input-group-prepend
        class="d-flex justify-content-center align-items-center rounded-left"
        style="width: 48px; height:38px;"
        :style="{ backgroundColor: design.colors.answerColor }"
      >
        <b-icon icon="envelope-fill" font-scale="1.5" style="color: white;" />
      </b-input-group-prepend>
      <b-form-input type="email"></b-form-input>
    </b-input-group>
  </div>
</template>

<script>
export default {
  props: {
    node: {
      type: Object
    },
    design: {
      type: Object
    }
  }
};
</script>
