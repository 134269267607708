import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import * as types from "./types";

export default {
  getTeam: async (context, { teamId }) => {
    try {
      const team = await API.get(API_NAME, `/teams/${teamId}`);
      context.commit(types.SET_SELECTED_TEAM, team);
    } catch (err) {
      context.commit(types.SET_GET_TEAM_ERROR, err);
    }
  },
  setTeam: async (context, value) => {
    try {
      context.commit(types.SET_SELECTED_TEAM, value);
    } catch (err) {
      context.commit(types.SET_GET_TEAM_ERROR, err);
    }
  },
  updateTeam: async (context, { teamId, name, description }) => {
    try {
      const body = { name, description };
      await API.post(API_NAME, `/teams/${teamId}/update`, {
        body
      });
    } catch (err) {
      context.commit(types.SET_GET_TEAM_ERROR, err);
    }
  },
  inviteTeam: async (context, { teamId, email }) => {
    try {
      const body = { email };
      await API.post(API_NAME, `/teams/${teamId}/invite`, {
        body
      });
    } catch (err) {
      context.commit(types.SET_GET_TEAM_ERROR, err);
    }
  },
  getTransactionList: async (context, { teamId }) => {
    try {
      const transaction = await API.get(API_NAME, `/transaction/${teamId}`);
      context.commit(types.GET_TRANSACTION_LIST, transaction);
    } catch (err) {
      context.commit(types.GET_TRANSACTION_LIST_ERROR, err);
    }
  },
  setSelectedPackage: (context, payload) => {
    context.commit(types.SET_SELECTED_PACKAGE, payload);
  },
  buyLicense: async (context, payload) => {
    try {
      const res = await API.post(API_NAME, `/transaction`, { body: payload });
      return res.paymentUrl;
    } catch (err) {
      context.commit(types.SET_BUY_LICENSE_ERROR, err);
    }
  },
  redeemPromo: async (context, { payload, vm }) => {
    try {
      await API.post(API_NAME, `/redeem`, { body: payload });
      vm.$toast.success(vm.$t("subscriptionModal.packageUpgraded"));
    } catch (err) {
      context.commit(types.SET_BUY_LICENSE_ERROR, err);
      vm.$toast.error(vm.$t("subscriptionModal.packageNotUpgraded"));
    }
  },
  setSelectedTeam: (context, { team }) => {
    context.commit(types.SET_SELECTED_TEAM, team);
  }
};
