export default {
  START_PAGE: "START_PAGE",
  STATEMENT: "STATEMENT",
  EMAIL: "EMAIL",
  TEL: "TEL",
  SCALE: "SCALE",
  MULTISELECT: "MULTISELECT",
  LONG_TEXT: "LONG_TEXT",
  SHORT_TEXT: "SHORT_TEXT",
  DROPDOWN: "DROPDOWN",
  DATE: "DATE",
  NUMBER: "NUMBER",
  END_PAGE: "END_PAGE",
  FILE_UPLOAD: "FILE_UPLOAD"
};
