export default {
  forms: state => state.forms,
  form: state => state.form,
  archiveForms: state => state.archiveForms,
  findForm: state => (id, version) =>
    state.forms.find(f => f.id === id && f.version === version),
  versions: state => state.forms.map(f => f.version),
  selectedVersion: state => state.selectedVersion,
  isFormChanged: state => state.isFormChanged,
  response: state =>
    state.response && state.response.Items
      ? state.response.Items.sort((a, b) => b.data.createdAt - a.data.createdAt)
      : [],
  responseByIndex: state => index => state.response.Items[index],
  showFormLimitWarning: state => state.showFormLimitWarning,
  activeStepGuide: state => state.activeStepGuide
};
